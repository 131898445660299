import React from 'react';
import './css/L03_Intro.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import IntroImg from './img/intro2.png';
import IntroImg1 from './img/intro2_1.png';
import IntroImg2 from './img/intro2_2.png';

function Intro_tab2() {
  setTimeout(function () {
    $('.tab2_img1').css('display', 'inline-block');
  }, 400);

  setTimeout(function () {
    $('.tab2_img2').css('display', 'inline-block');
  }, 800);

  return (
    <div className="tabWrap">
      <img
        src={IntroImg1}
        className="tab2_img1 animate__animated animate__jackInTheBox"
      />
      <img
        src={IntroImg2}
        className="tab2_img2 animate__animated animate__jackInTheBox"
      />
      <img src={IntroImg} className="tab_img" />
    </div>
  );
}

export default Intro_tab2;
