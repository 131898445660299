import React, { useState, useEffect, useRef } from 'react';
import './css/L11_sub_price.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Header from './L01_Header_sub';
import Inquiry from './L08_Inquiry_sub';
import Meta from '../MetaTag';
import priceArrow from '../../images/priceArrow.png';
import { FiCheck } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';
import { IoIosHelpCircle } from 'react-icons/io';
import { Popup } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { isDesktop, isMobile } from 'react-device-detect';

function L11_sub_price() {
  return (
    <>
      <Meta />
      <div className="priceWrap col-12 d-flex flex-column">
        <Header />
        <h2 className="priceMainTitle">
          <b>샵솔</b>서비스 요금 정보
        </h2>
        <div className="content">
          <div className="card">
            <div className="cardHeader">
              <div className="cardTitle">무료</div>
              <div className="cardDesc">
                <p>무료로 제공되는 필수 기능을</p>
                <p>사용하여 직원관리를 해보세요</p>
              </div>
            </div>
            <div className="cardMenu">
              <div className="freeText">
                <strong>Free</strong>
              </div>
            </div>
            <div className="bar" />
            <div className="cardInfo">
              <div className="cardInfoTitle">{planList[0].title}</div>
              <div className="cardInfoList">
                {planList[0].list.map((i) => {
                  return (
                    <div className="cardInfoRow" key={`free_${i.content}`}>
                      <div
                        className="cardInfoMark"
                        style={{
                          backgroundColor:
                            i.isBlueMark === true ? '#AECFFF' : '#E5F4F2',
                        }}
                      >
                        <FiCheck
                          color={i.isBlueMark === true ? '#0045AD' : '#49AE9E'}
                        />
                      </div>
                      <div className="cardInfoContent">
                        <span>{i.content}</span>
                      </div>
                      {i.lastKeword && (
                        <div className="cardInfoKeyword">{i.lastKeword}</div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="cardHeader">
              <div className="cardTitle">
                <div className="titlePointer" />
                베이직
              </div>
              <div className="cardDesc">
                <p>앱 전체 기능 무제한 제공</p>
              </div>
            </div>
            <div className="cardMenu">
              <div className="priceMenu">
                <div className="priceRow">
                  <div className="priceMonth">1개월</div>
                  <div className="priceValue">
                    9,000<span>원</span>
                  </div>
                </div>
                <div className="priceRow">
                  <div className="priceMonth">6개월</div>
                  <div className="priceDiscount">
                    54,000<span>원</span>
                    <div className="discountArrow">
                      <img src={priceArrow} />
                    </div>
                  </div>
                  <div className="priceValue">
                    45,000<span>원</span>
                  </div>
                </div>
                <div className="priceRow">
                  <div className="priceMonth">12개월</div>
                  <div className="priceDiscount">
                    108,000<span>원</span>
                    <div className="discountArrow">
                      <img src={priceArrow} />
                    </div>
                  </div>
                  <div className="priceValue">
                    81,000<span>원</span>
                  </div>
                </div>
              </div>
              <div className="priceDesc">
                <span>*부가세는 10% 별도입니다.</span>
              </div>
              <div className="priceBtn">
                <button
                  onClick={() => {
                    if (isDesktop) {
                      dataLayer.push({
                        event: 'view_content',
                        content_name: 'header_login_ceo',
                        next_url: 'http://ceo.shop-sol.com',
                        url_path: window.location.pathname,
                      });
                      window.open('http://ceo.shop-sol.com', '_self');
                    } else {
                      alert('결제는 PC에서 하실 수 있습니다.');
                    }
                  }}
                >
                  결제하기
                </button>
              </div>
            </div>
            <div className="bar" />
            <div className="cardInfo">
              <div className="cardInfoTitle">{planList[1].title}</div>
              <div className="cardInfoList">
                {planList[1].list.map((i) => {
                  return (
                    <div className="cardInfoRow" key={`basic_${i.content}`}>
                      <div
                        className="cardInfoMark"
                        style={{
                          backgroundColor:
                            i.isBlueMark === true ? '#AECFFF' : '#E5F4F2',
                        }}
                      >
                        <FiCheck
                          color={i.isBlueMark === true ? '#0045AD' : '#49AE9E'}
                        />
                      </div>
                      <div className="cardInfoContent">
                        <span>{i.content}</span>
                      </div>
                      {i.lastKeword && (
                        <div className="cardInfoKeyword">{i.lastKeword}</div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="cardHeader">
              <div className="cardTitle">
                <div
                  className="titlePointer"
                  style={{ backgroundColor: '#839EFC' }}
                />
                비즈니스
              </div>
              <div className="cardDesc">
                <p>앱+PC 전체 기능 무제한 제공</p>
              </div>
            </div>
            <div className="cardMenu">
              <div className="priceMenu">
                <div className="priceRow">
                  <div className="priceMonth">1개월</div>
                  <div className="priceValue">
                    18,000<span>원</span>
                  </div>
                </div>
                <div className="priceRow">
                  <div className="priceMonth">6개월</div>
                  <div className="priceDiscount">
                    108,000<span>원</span>
                    <div className="discountArrow">
                      <img src={priceArrow} />
                    </div>
                  </div>
                  <div className="priceValue">
                    81,000<span>원</span>
                  </div>
                </div>
                <div className="priceRow">
                  <div className="priceMonth">12개월</div>
                  <div className="priceDiscount">
                    216,000<span>원</span>
                    <div className="discountArrow">
                      <img src={priceArrow} />
                    </div>
                  </div>
                  <div className="priceValue">
                    162,000<span>원</span>
                  </div>
                </div>
              </div>
              <div className="priceDesc">
                <span>*부가세는 10% 별도입니다.</span>
              </div>
              <div className="priceBtn">
                <button
                  onClick={() => {
                    if (isDesktop) {
                      dataLayer.push({
                        event: 'view_content',
                        content_name: 'header_login_ceo',
                        next_url: 'http://ceo.shop-sol.com',
                        url_path: window.location.pathname,
                      });
                      window.open('http://ceo.shop-sol.com', '_self');
                    } else {
                      alert('결제는 PC에서 하실 수 있습니다.');
                    }
                  }}
                >
                  결제하기
                </button>
              </div>
            </div>
            <div className="bar" />
            <div className="cardInfo">
              <div className="cardInfoTitle">{planList[2].title}</div>
              <div className="cardInfoList">
                {planList[2].list.map((i) => {
                  return (
                    <div className="cardInfoRow" key={`business_${i.content}`}>
                      <div
                        className="cardInfoMark"
                        style={{
                          backgroundColor:
                            i.isBlueMark === true ? '#AECFFF' : '#E5F4F2',
                        }}
                      >
                        <FiCheck
                          color={i.isBlueMark === true ? '#0045AD' : '#49AE9E'}
                        />
                      </div>
                      <div className="cardInfoContent">
                        <span>{i.content}</span>
                      </div>
                      {i.lastKeword && (
                        <div className="cardInfoKeyword">{i.lastKeword}</div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="cardHeader">
              <div className="cardTitle">프리미엄</div>
              <div className="cardDesc">
                <p>커스터마이징</p>
              </div>
            </div>
            <div className="cardMenu">
              <div className="customText">
                <p>직원수 100명 이상,</p>
                <p>점포수 50개 이상 사업장에</p>
                <p>추천합니다!</p>
              </div>
              <div className="enterBtn">
                <button
                  onClick={() => {
                    window.open('https://bit.ly/3E2fXjO', '_blank');
                  }}
                >
                  도입문의
                </button>
              </div>
            </div>
            <div className="bar" />
            <div className="cardInfo">
              <div className="cardInfoTitle">{planList[3].title}</div>
              <div className="cardInfoList">
                {planList[3].list.map((i) => {
                  return (
                    <div className="cardInfoRow" key={`custom1_${i.content}`}>
                      <div
                        className="cardInfoMark"
                        style={{
                          backgroundColor:
                            i.isBlueMark === true ? '#AECFFF' : '#E5F4F2',
                        }}
                      >
                        <FiCheck
                          color={i.isBlueMark === true ? '#0045AD' : '#49AE9E'}
                        />
                      </div>
                      <div className="cardInfoContent">
                        <span>{i.content}</span>
                      </div>
                      {i.lastKeword && (
                        <div className="cardInfoKeyword">{i.lastKeword}</div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="cardInfoTitle">{planList[4].title}</div>
              <div className="cardInfoList">
                {planList[4].list.map((i) => {
                  return (
                    <div className="cardInfoRow" key={`custom2_${i.content}`}>
                      <div
                        className="cardInfoMark"
                        style={{
                          backgroundColor:
                            i.isBlueMark === true ? '#AECFFF' : '#E5F4F2',
                        }}
                      >
                        <FiCheck
                          color={i.isBlueMark === true ? '#0045AD' : '#49AE9E'}
                        />
                      </div>
                      <div className="cardInfoContent">
                        <span>{i.content}</span>
                      </div>
                      {i.lastKeword && (
                        <div className="cardInfoKeyword">{i.lastKeword}</div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="functions">
          <div className="functionsPlan">
            <div className="functionsRow">
              <div className="functionsTitle">
                <strong>플랜별 기능</strong>
              </div>
              <div className="functionsContent">
                <div className="functionsColumn">
                  <div className="columnTitle">
                    <div
                      className="titlePointer"
                      style={{ backgroundColor: '#A8A8A8' }}
                    />
                    무료
                  </div>
                </div>
                <div className="functionsColumn">
                  <div className="columnTitle">
                    <div className="titlePointer" />
                    베이직
                  </div>
                </div>
                <div className="functionsColumn">
                  <div className="columnTitle">
                    <div
                      className="titlePointer"
                      style={{ backgroundColor: '#839EFC' }}
                    />
                    비즈니스
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="functionsList">
            {functionListMain.map((i) => {
              return (
                <div
                  className="functionsRow"
                  key={`functionListMain_${i.title}`}
                >
                  <div className="functionsTitle">
                    <span>{i.title}</span>
                    <Popup
                      flowing={true}
                      trigger={
                        <IoIosHelpCircle
                          size={18}
                          color="#C6C6C6"
                          style={{ marginLeft: 5 }}
                        />
                      }
                      position={isMobile ? 'bottom left' : 'top center'}
                      inverted
                      content={i.desc}
                      style={{ borderRadius: 4 }}
                    />
                  </div>
                  <div className="functionsContent">
                    <div className="functionsColumn">
                      <div className="columnContent">
                        {FunctionsItem(i.free)}
                      </div>
                    </div>
                    <div className="functionsColumn">
                      <div className="columnContent">
                        {FunctionsItem(i.basic)}
                      </div>
                    </div>
                    <div className="functionsColumn">
                      <div className="columnContent">
                        {FunctionsItem(i.business)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="functionsList"
            style={{ borderColor: '#B0B0B0', backgroundColor: 'white' }}
          >
            {functionListSub.map((i) => {
              return (
                <div
                  className="functionsRow"
                  key={`functionListSub_${i.title}`}
                >
                  <div className="functionsTitle">
                    <span>{i.title}</span>
                    <Popup
                      flowing={true}
                      trigger={
                        <IoIosHelpCircle
                          size={18}
                          color="#C6C6C6"
                          style={{ marginLeft: 5 }}
                        />
                      }
                      position={isMobile ? 'bottom left' : 'top center'}
                      inverted
                      content={i.desc}
                      style={{ borderRadius: 4, maxWidth: 200 }}
                    />
                  </div>
                  <div className="functionsContent">
                    <div className="functionsColumn">
                      <div className="columnContent">
                        {FunctionsItem(i.free)}
                      </div>
                    </div>
                    <div className="functionsColumn">
                      <div className="columnContent">
                        {FunctionsItem(i.basic)}
                      </div>
                    </div>
                    <div className="functionsColumn">
                      <div className="columnContent">
                        {FunctionsItem(i.business)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default L11_sub_price;

const infiniteText = <span style={{ color: '#4891FF' }}>&nbsp;무제한</span>;
const countText = (text) => {
  return <span style={{ color: '#49AE9E' }}>{text}</span>;
};
const expectText = <span style={{ color: '#EE5951' }}> *출시 예정</span>;

const planList = [
  {
    title: <strong>앱에서 사용 가능</strong>,
    list: [
      { content: '직원등록', lastKeword: infiniteText },
      { content: '사업장 등록', lastKeword: countText(' 총 1개') },
      { content: '급여명세서 발급', lastKeword: countText(' 월 5회') },
      { content: '체크리스트', lastKeword: countText(' 총 5개') },
      { content: '유통기한 관리', lastKeword: infiniteText },
      { content: '업무일지', lastKeword: infiniteText },
      { content: '업무캘린더', lastKeword: infiniteText },
      { content: '위생교육증 관리', lastKeword: infiniteText },
      { content: '보건증 관리', lastKeword: infiniteText },
    ],
  },
  {
    title: (
      <strong>
        앱에서<span style={{ color: '#4891FF' }}> 무제한</span> 사용 가능
      </strong>
    ),
    list: [
      { content: '직원등록', lastKeword: infiniteText },
      { content: '사업장 등록', lastKeword: infiniteText },
      { content: '급여명세서 발급', lastKeword: infiniteText },
      { content: '체크리스트', lastKeword: infiniteText },
      { content: '유통기한 관리', lastKeword: infiniteText },
      { content: '업무일지', lastKeword: infiniteText },
      { content: '업무캘린더', lastKeword: infiniteText },
      { content: '위생교육증 관리', lastKeword: infiniteText },
      { content: '보건증 관리', lastKeword: infiniteText },
      // { content: '전자근로계약서', lastKeword: expectText },
    ],
  },
  {
    title: (
      <strong>
        앱 + PC에서<span style={{ color: '#4891FF' }}> 무제한</span> 사용 가능
      </strong>
    ),
    list: [
      { content: '직원등록', lastKeword: infiniteText },
      { content: '사업장 등록', lastKeword: infiniteText },
      { content: '급여명세서 발급', lastKeword: infiniteText },
      { content: '체크리스트', lastKeword: infiniteText },
      { content: '유통기한 관리', lastKeword: infiniteText },
      { content: '업무일지', lastKeword: infiniteText },
      { content: '업무캘린더', lastKeword: infiniteText },
      { content: '위생교육증 관리', lastKeword: infiniteText },
      { content: '보건증 관리', lastKeword: infiniteText },
      // { content: '전자근로계약서', lastKeword: expectText },
      { content: '급여대장(엑셀)', lastKeword: infiniteText },
      { content: '출퇴근일지(엑셀)', lastKeword: infiniteText },
    ],
  },
  {
    title: (
      <strong>
        앱 + PC에서<span style={{ color: '#4891FF' }}> 무제한</span> 사용 가능
      </strong>
    ),
    list: [
      { content: '유료 서비스와 동일한 기능' },
      { content: '전자근로계약서 제공' },
      { content: '사업장 맞춤 기능 개발' },
      { content: '사업장 단독 앱 제공' },
    ],
  },
  {
    title: <strong>프리미엄을 사용중인 기업</strong>,
    list: [
      { content: 'BGF리테일(CU편의점)', isBlueMark: true },
      { content: 'BGF휴먼넷', isBlueMark: true },
      { content: '코레일유통', isBlueMark: true },
      { content: '써브웨이', isBlueMark: true },
      { content: '한화건설', isBlueMark: true },
    ],
  },
];

const FunctionsItem = (text) => {
  if (text === 'check') {
    return <FiCheck color={'#008069'} size={isMobile ? 20 : 30} />;
  } else if (text === 'no') {
    return <IoClose color={'#EE5454'} size={isMobile ? 20 : 30} />;
  } else {
    return <strong style={{ color: '#008069' }}>{text}</strong>;
  }
};
const functionListMain = [
  {
    title: 'App',
    desc: '휴대폰에서 샵솔 앱을 설치하면 사용할 수 있습니다.',
    free: 'check',
    basic: 'check',
    business: 'check',
  },
  {
    title: 'PC',
    desc: '앱과 PC가 연동되어 편하게 직원관리를 할 수 있습니다.',
    free: 'no',
    basic: 'no',
    business: 'check',
  },
  {
    title: '사업장수',
    desc: '관리가 필요한 사업장을 등록합니다.',
    free: '1개',
    basic: '무제한',
    business: '무제한',
  },
  {
    title: '직원등록',
    desc: '관리할 직원을 초대하여 등록합니다.',
    free: '무제한',
    basic: '무제한',
    business: '무제한',
  },
];
const functionListSub = [
  {
    title: `체크리스트`,
    desc: '꼼꼼한 업무를 위해 체크리스트 목록을 만들어 직원에게 전달합니다.',
    free: '5개',
    basic: '무제한',
    business: '무제한',
  },
  {
    title: `급여명세서`,
    desc: '5인 미만 사업장도 급여명세서 교부는 의무입니다.',
    free: '월 5회',
    basic: '무제한',
    business: '무제한',
  },
  {
    title: `중간관리자 등록`,
    desc: '직원에게 관리자 권한을 부여할 수 있습니다.',
    free: 'check',
    basic: 'check',
    business: 'check',
  },
  {
    title: `업무일지`,
    desc: '업무 중 발생하는 특이사항 및 공지사항을 전달합니다.',
    free: 'check',
    basic: 'check',
    business: 'check',
  },
  {
    title: `보건증 관리`,
    desc: '보건증이 만료되기 전 갱신 알림이 발송됩니다.',
    free: 'check',
    basic: 'check',
    business: 'check',
  },
  {
    title: `위생교육증 관리`,
    desc: '위생교육증이 만료되기 전 갱신 알림이 발송됩니다.',
    free: 'check',
    basic: 'check',
    business: 'check',
  },
  {
    title: `유통기한 관리`,
    desc: '유통기한이 있는 식품을 관리할 수 있습니다.',
    free: 'check',
    basic: 'check',
    business: 'check',
  },
  {
    title: `사업장 현황`,
    desc: '직원 근태 현황을 자세히 확인할 수 있습니다.',
    free: 'check',
    basic: 'check',
    business: 'check',
  },
  {
    title: `캘린더`,
    desc: '직원 출퇴근 시간 확인 및 근무일정 수정을 할 수 있습니다.',
    free: 'check',
    basic: 'check',
    business: 'check',
  },
  {
    title: `급여대장 (엑셀)`,
    desc: '급여대장은 PC에서 다운로드할 수 있습니다.',
    free: 'no',
    basic: 'no',
    business: 'check',
  },
  {
    title: `출퇴근기록 (엑셀)`,
    desc: '출퇴근기록은 PC에서 다운로드할 수 있습니다.',
    free: 'no',
    basic: 'no',
    business: 'check',
  },
  {
    title: `출퇴근 상세 확인`,
    desc: '출퇴근기록을 상세하게 살펴볼 수 있습니다.',
    free: 'no',
    basic: 'no',
    business: 'check',
  },
  {
    title: `직원 대량 등록`,
    desc: '직원수가 많아도 엑셀로 한번에 등록 가능합니다.',
    free: 'no',
    basic: 'no',
    business: 'check',
  },
  {
    title: `근로템플릿`,
    desc: '근로템플릿을 만들어두면 다수의 직원에게 한꺼번에 적용 가능합니다.',
    free: 'no',
    basic: 'no',
    business: 'check',
  },
  {
    title: `일정템플릿`,
    desc: '일정템플릿을 만들어두면 다수의 직원에게 한꺼번에 적용 가능합니다.',
    free: 'no',
    basic: 'no',
    business: 'check',
  },
  {
    title: `체크리스트 양식`,
    desc: '사업장 카테고리별 자주 사용하는 체크리스트 양식을 제공합니다.',
    free: 'no',
    basic: 'no',
    business: 'check',
  },
];
