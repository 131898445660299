import React from 'react';
import { Helmet } from 'react-helmet';

const defaultTitle = '소상공인 올인원 HR 솔루션 | 샵솔!'

const Meta = ({ Title = defaultTitle }) => {
    return (
        <Helmet>
          {/* <!-- HTML Meta Tags --> */}
          <title>{Title}</title>
          <meta
            name="description"
            content="급여관리, 급여명세서 발송, 근태관리, 업무관리에 효율적인 소상공인 특화 HR 솔루션"
          />
    
          {/* <!-- Google / Search Engine Tags --> */}
          <meta itemprop="name" content="소상공인 올인원 HR 솔루션 | 샵솔" />
          <meta
            itemprop="description"
            content="급여관리, 급여명세서 발송, 근태관리, 업무관리에 효율적인 소상공인 특화 HR 솔루션"
          />
          <meta
            name="keywords"
            content="샵솔, 근태관리, 직원관리, 업무관리, 인력관리, 통합 HR 시스템, 소상공인 인력관리, 프랜차이즈 인력관리, 출퇴근기록, 출퇴근관리, 부정출퇴근, 소상공인 HR 서비스, 급여관리, 자동급여계산기, 급여정산, 인사담당자, 근태, 급여, 근로기준법, 주52시간, 유연근무제, 근무기록, 인사관리 플랫폼, 유통기한 관리, 체크리스트, 전자계약서, 근태 기록, 급여명세서 양식, 급여명세서 발급, 급여명세서, 급여명세서 발송, 급여명세서 앱, 급여명세서 교부, 임금명세서, 임금명세서 교부, 임금명세서 발송, 4대보험, 초과근무수당, HR솔루션, HR, 주69시간, 근무시간 기록, 포괄임금제, 근로시간 위반, 임금체불, 근로시간 개편, 근로시간 선택권, 유연근무"
          />
          <meta
            itemprop="image"
            content="http://wesop.s3.ap-northeast-2.amazonaws.com/shopsol/landing_web_image.jpg"
          />
    
          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content="https://www.shop-sol.com" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="소상공인 올인원 HR 솔루션 | 샵솔"
          />
          <meta
            property="og:description"
            content="급여관리, 급여명세서 발송, 근태관리, 업무관리에 효율적인 소상공인 특화 HR 솔루션"
          />
          <meta
            property="og:image"
            content="http://wesop.s3.ap-northeast-2.amazonaws.com/shopsol/landing_web_image.jpg"
          />
    
          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="소상공인 올인원 HR 솔루션 | 샵솔"
          />
          <meta
            name="twitter:description"
            content="급여관리, 급여명세서 발송, 근태관리, 업무관리에 효율적인 소상공인 특화 HR 솔루션"
          />
          <meta
            name="twitter:image"
            content="http://wesop.s3.ap-northeast-2.amazonaws.com/shopsol/landing_web_image.jpg"
          />
    
          <meta name="apple-itunes-app" content="app-id=1408364175" />
          <meta
            name="google-play-app"
            content="app-id=com.wesop.appshopsol"
          />
          <meta name="naver-site-verification" content="f048fc9034bfaa70a15132bb59d7f3e5fe93d678" />
          <meta name="facebook-domain-verification" content="mdq1nghqybeyhe6aztbkb18ofvij34" />
    
          {/* <!-- Meta Tags Generated via http://heymeta.com --> */}
        </Helmet>
    )
}

export default Meta;