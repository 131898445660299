import React, { useState, useEffect, useRef } from "react";
import './css/Custom.scss';
import './css/style.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import Header from './L01_Header_sub';
import Meta from '../MetaTag';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faChartBar, 
    faWalking, 
    faCoins, 
    faCalendarAlt, 
    faComments, 
    faChalkboard, 
    faEnvelope,
    faMapMarkerAlt,
    faTasks,
    faCodeBranch
} from "@fortawesome/free-solid-svg-icons";

function L13_sub_custom() {
    return (
        <>
        <Meta />
        <div id="customWrap" className="col-12">
            <Header/>
            <div className="shopsol col-12">
                <p className="subTitle">100개 이상 가맹점 소유 프랜차이즈</p>
                <h2 className="mainTitle">샵솔 커스터마이징</h2>
                <div className="cntWrap col-12">
                    <div className="cntTop">
                        <div className="cntBox">
                            <FontAwesomeIcon icon={faChartBar} className="icon" />
                            <h3 className="boxTitle">기업이 원하는 업무<br/>시스템 환경 구축</h3>
                        </div>
                        <div className="cntBox">
                            <FontAwesomeIcon icon={faWalking} className="icon" />
                            <h3 className="boxTitle">근태관리에 최적화된<br/>출퇴근 기록기</h3>
                        </div>
                        <div className="cntBox">
                            <FontAwesomeIcon icon={faCoins} className="icon" />
                            <h3 className="boxTitle">간소화된<br/>급여정산</h3>
                        </div>
                        <div className="cntBox">
                            <FontAwesomeIcon icon={faCalendarAlt} className="icon" />
                            <h3 className="boxTitle">고도화된<br/>유통기한 관리 시스템</h3>
                        </div>
                    </div>
                    <div className="cntBottom">
                        <div className="cntBox">
                            <FontAwesomeIcon icon={faComments} className="icon" />
                            <h3 className="boxTitle">본사-점주-직원의<br/>원활한 소통</h3>
                        </div>
                        <div className="cntBox">
                            <FontAwesomeIcon icon={faChalkboard} className="icon" />
                            <h3 className="boxTitle">현장교육을 뛰어넘는<br/>비대면 교육시스템 탑재</h3>
                        </div>
                        <div className="cntBox">
                            <FontAwesomeIcon icon={faEnvelope} className="icon" />
                            <h3 className="boxTitle">무료 PUSH를 통한<br/>비용 절감</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="semi col-12">
                <p className="subTitle">100개 미만 가맹점 소유 프랜차이즈</p>
                <h2 className="mainTitle">세미 커스터마이징</h2>
                <div className="cntWrap col-12">
                    <div className="cntTop">
                        <div className="cntBox">
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                            <h3 className="boxTitle">GPS 인증 방식의<br/>출퇴근 시간 기록</h3>
                        </div>
                        <div className="cntBox">
                            <FontAwesomeIcon icon={faCoins} className="icon" />
                            <h3 className="boxTitle">간소화된<br/>급여정산</h3>
                        </div>
                        <div className="cntBox">
                            <FontAwesomeIcon icon={faTasks} className="icon" />
                            <h3 className="boxTitle">전국 가맹점<br/>집중 관리 시스템 탑재</h3>
                        </div>
                    </div>
                    <div className="cntBottom">
                        <div className="cntBox">
                            <FontAwesomeIcon icon={faCodeBranch} className="icon" />
                            <h3 className="boxTitle">앱과 웹의<br/>동기화</h3>
                        </div>
                        <div className="cntBox">
                            <FontAwesomeIcon icon={faEnvelope} className="icon" />
                            <h3 className="boxTitle">무료 PUSH를 통한<br/>비용 절감</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="InquiryAndFooterWrap d-flex flex-column justify-content-center align-items-center col-12">
            <div className="InquiryWrap d-flex flex-column justify-content-center align-content-center text-center col-12">
                <p>체계적이고 효율적인 근무환경</p>
                <h1>샵솔·세미 커스터마이징을 사용해 볼까요?</h1>
                <a
                    target="_blank"
                    href="https://bit.ly/3E2fXjO"
                    onClick={() => {
                        dataLayer.push({
                            event: 'view_content',
                            content_name: 'introduction_custom',
                            next_url: 'https://bit.ly/3E2fXjO',
                            url_path: window.location.pathname
                        })
                    }}
                >도입문의</a>
            </div>
        </div>
        </div>
    </>
    )
}

export default L13_sub_custom;