import React from "react";
import './css/L08_Inquiry.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import InquiryBack from './img/inquiryBack.png'

function L10_InquiryAndFooter() {
    return (
        <div className="InquiryAndFooterWrap_sub d-flex flex-column justify-content-center align-items-center col-12">
            <div className="InquiryWrap d-flex flex-column justify-content-center align-content-center text-center col-12">
                <p>체계적이고 효율적인 근무환경</p>
                <h1>지금 바로 샵솔을 도입해 보세요!</h1>
                <a
                    target="_blank"
                    href="https://bit.ly/3E2fXjO"
                    onClick={() => {
                        dataLayer.push({
                            event: 'view_content',
                            content_name: 'introduction_price',
                            next_url: 'https://bit.ly/3E2fXjO',
                            url_path: window.location.pathname
                        })
                    }}
                >도입문의</a>
            </div>
        </div>
    )
}

export default L10_InquiryAndFooter;