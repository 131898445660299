import React from "react";
import "./css/L15_goTop.scss";
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

function L15_goTop (props) {
    const { onClick } = props;
    return (
        <a className="topBtnWrap" onClick={onClick}>
            <FontAwesomeIcon icon={faPlay} className="btnIcon"/>
            <p className="btnText">TOP</p>
        </a>
    )
}

export default L15_goTop;