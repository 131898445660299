import React, { useEffect } from 'react';
import './css/L06_Brand.scss';
import 'bootstrap/dist/css/bootstrap.css';
import Div100vh from 'react-div-100vh';
import $ from './jquery';
import brand_BBQ from './img/brands/brand_BBQ.png';
import brand_bgf from './img/brands/brand_bgf.png';
import brand_humanNet from './img/brands/brand_bgf2.png';
import brand_bhc from './img/brands/brand_bhc.png';
import brand_daehanCJ from './img/brands/brand_daehanCJ.png';
import brand_dookki from './img/brands/brand_dookki.png';
import brand_ediya from './img/brands/brand_ediya.png';
import brand_emart from './img/brands/brand_emart.png';
import brand_gongcha from './img/brands/brand_gongcha.png';
import brand_goobne from './img/brands/brand_goobne.png';
import brand_hanhwa from './img/brands/brand_hanhwa.png';
import brand_hollys from './img/brands/brand_hollys.png';
import brand_hongkong from './img/brands/brand_hongkong.png';
import brand_korail from './img/brands/brand_korail.png';
import brand_kyochon from './img/brands/brand_kyochon.png';
import brand_megaCoffee from './img/brands/brand_megaCoffee.png';
import brand_momsTouch from './img/brands/brand_momsTouch.png';
import brand_nene from './img/brands/brand_nene.png';
import brand_olive from './img/brands/brand_olive.png';
import brand_paiksCoffee from './img/brands/brand_paiksCoffee.png';
import brand_paris from './img/brands/brand_paris.png';
import brand_sinjeon from './img/brands/brand_sinjeon.png';
import brand_sulbing from './img/brands/brand_sulbing.png';
import brand_theVenti from './img/brands/brand_theVenti.png';
import brand_osim from './img/brands/brand_osim.png';
import brand_twosomePlace from './img/brands/brand_twosomePlace.png';
import brand_yeokjeon from './img/brands/brand_yeokjeon.png';
import brand_frankBurger from './img/brands/brand_frankBurger.png';
import brand_leeSeongDang from './img/brands/brand_leeSeongDang.png';

import brand_mobile_bgf from './img/brands/brand_mobile_bgf.png';
import brand_mobile_bgf2 from './img/brands/brand_mobile_bgf2.png';
import brand_mobile_korail from './img/brands/brand_mobile_korail.png';
import brand_mobile_hanhwa from './img/brands/brand_mobile_hanhwa.png';

function L06_Brand() {
  const counter = ($counter, max) => {
    let now = max;

    const handle = setInterval(() => {
      $counter.innerHTML =
        Math.ceil(max - now)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '개 이상';

      // 목표수치에 도달하면 정지
      if (now < 1) {
        clearInterval(handle);
      }

      // 증가되는 값이 계속하여 작아짐
      const step = now / 10;

      // 값을 적용시키면서 다음 차례에 영향을 끼침
      now -= step;
    }, 30);
  };

  window.onload = () => {
    // 카운트를 적용시킬 요소
    const $counter = document.querySelector('.count');

    // 목표 수치
    const max = 40000;

    counter($counter, max);
    setInterval(() => {
      counter($counter, max);
    }, 8000);
  };

  return (
    <Div100vh>
      <div
        id="ContentWrap"
        className="d-flex flex-row justify-content-center align-items-center"
      >
        <div id="brandCntWrap">
          <div id="pcToTabletScreen">
            <div className="topWrap col-11">
              <h2 className="brandText1">
                <b>프랜차이즈 주요 고객사</b>
              </h2>
              <h2>전 가맹점이 샵솔을 통해 체계적으로 관리를 하고 있어요.</h2>
              <div className="mainImgWrap col-11">
                <div className="mainImgBox">
                  <img src={brand_mobile_bgf} className="mainLogoImg" />
                </div>
                <div className="mainImgBox">
                  <img src={brand_mobile_bgf2} className="mainLogoImg" />
                </div>
                <div className="mainImgBox">
                  <img src={brand_mobile_korail} className="mainLogoImg" />
                </div>
                <div className="mainImgBox">
                  <img src={brand_mobile_hanhwa} className="mainLogoImg" />
                </div>
              </div>
            </div>
            <h2 className="brandText2">
              샵솔은 개별 가맹점에서도 영향력을 넓히고 있어요.
            </h2>
            <div className="subImgWrap col-10">
              <div className="subImgBox">
                <img src={brand_BBQ} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_bhc} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_daehanCJ} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_dookki} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_ediya} className="logoImg" />
              </div>
            </div>
            <div className="subImgWrap col-10">
              <div className="subImgBox">
                <img src={brand_emart} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_gongcha} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_goobne} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_hollys} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_hongkong} className="logoImg" />
              </div>
            </div>
            <div className="subImgWrap col-10">
              <div className="subImgBox">
                <img src={brand_kyochon} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_megaCoffee} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_momsTouch} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_nene} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_olive} className="logoImg" />
              </div>
            </div>
            <div className="subImgWrap col-10">
              <div className="subImgBox">
                <img src={brand_paiksCoffee} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_paris} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_sinjeon} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_sulbing} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_theVenti} className="logoImg" />
              </div>
            </div>
            <div className="subImgWrap col-10">
              <div className="subImgBox">
                <img src={brand_osim} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_twosomePlace} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_yeokjeon} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_frankBurger} className="logoImg" />
              </div>
              <div className="subImgBox">
                <img src={brand_leeSeongDang} className="logoImg" />
              </div>
            </div>
          </div>
          <div id="tabletToMobileScreen">
            <div className="colorBox col-10">
              <h2 className="brandText1">
                <b>프랜차이즈 주요 고객사</b>
              </h2>
              <h2 className="explain">
                전 가맹점이 샵솔을 통해
                <br />
                체계적으로 관리를 하고 있어요.
              </h2>
              <div className="topBox mobileImgWrap col-12">
                <div className="mobileImgBox">
                  <img src={brand_mobile_bgf} className="logoImg" />
                </div>
                <div className="mobileImgBox">
                  <img src={brand_mobile_korail} className="logoImg" />
                </div>
              </div>
              <div className="mobileImgWrap col-12">
                <div className="mobileImgBox">
                  <img src={brand_mobile_bgf2} className="logoImg" />
                </div>
                <div className="mobileImgBox">
                  <img src={brand_mobile_hanhwa} className="logoImg" />
                </div>
              </div>
            </div>
            <div className="bottomTextWrap">
              <p className="bottomText">이 외에</p>
              <p className="count"></p>
              <p className="bottomText">
                개별 사업장에서 샵솔을 사용하고 있어요.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Div100vh>
  );
}

export default L06_Brand;
