import React from 'react';
import './css/L04_Section.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import SectionImg from './img/section3.png';
import SectionImg1 from './img/section3_1.png';
import SectionImg2 from './img/section3_2.png';
import SectionImg3 from './img/section3_3.png';
import Div100vh from 'react-div-100vh';

function L04_Section03() {
  setTimeout(function () {
    $('.section3_img1').css('display', 'inline-block');
  }, 400);

  setTimeout(function () {
    $('.section3_img2').css('display', 'inline-block');
  }, 800);

  setTimeout(function () {
    $('.section3_img3').css('display', 'inline-block');
  }, 1200);

  return (
    <Div100vh>
      <div
        id="ContentWrap"
        className="col-12 d-flex flex-row justify-content-center align-items-center overflow-hidden"
      >
        <div
          id="cntWrap"
          className="d-flex justify-content-center align-items-center"
        >
          <div className="d-flex flex-row justify-content-center align-items-center">
            <div className="itemWrap">
              <img
                className="itemImg section3_img1 animate__animated animate__pulse"
                alt="기능소개이미지"
                src={SectionImg1}
              />
              <img
                className="itemImg section3_img2 animate__animated animate__pulse"
                alt="기능소개이미지"
                src={SectionImg2}
              />
              <img
                className="itemImg section3_img3 animate__animated animate__pulse"
                alt="기능소개이미지"
                src={SectionImg3}
              />
            </div>
            <img
              className="section_img3"
              alt="기능소개이미지"
              src={SectionImg}
            />
          </div>
          <div className="col-xl-5 col-lg-4 col-md-12 col-sm-12">
            <p className="sectionMiniTitle">오늘할일서비스</p>
            <h2 className="sectionTitle">
              출근 처리와 동시에
              <br />
              오늘 할 일 체크
            </h2>
            <p className="sectionText">
              [오늘할일서비스]를 제공하여
              <br />
              꼼꼼한 사업장관리를 도와줍니다.
            </p>
          </div>
        </div>
      </div>
    </Div100vh>
  );
}

export default L04_Section03;
