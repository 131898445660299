import React from 'react';
import './css/L05_WebManager.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import Div100vh from 'react-div-100vh';

function L05_WebManager() {
  return (
    <Div100vh>
      <div
        id="WebImg"
        className="col-12 d-flex flex-row justify-content-center align-items-center overflow-hidden"
      >
        <div
          id="WebCnt"
          className="col-xl-9 col-lg-10 d-flex flex-row justify-content-start align-items-center"
        >
          <div className="col-xl-6 col-lg-12 d-flex flex-column">
            <div className="miniTitleWrap">
              <p className="sectionP">강력한 PC관리자</p>
            </div>
            <h2 className="WebTitle">
              앱과 연동되는 PC에서
              <br />
              편하게 관리해 보세요!
            </h2>
            <p className="WebP">
              · 다수 직원 일괄 등록 기능 <br />
              · 출퇴근기록, 급여대장 다운로드 기능
              <br />· 실시간 직원 근무현황 대시보드 제공
            </p>
          </div>
        </div>
      </div>
    </Div100vh>
  );
}

export default L05_WebManager;
