import React from 'react';
import './css/L04_Section.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import SectionImg from './img/section4.png';
import SectionImg1 from './img/intro2_1.png';
import SectionImg2 from './img/intro2_2.png';
import Div100vh from 'react-div-100vh';

function L04_Section02() {
  setTimeout(function () {
    $('.section4_img1').css('display', 'inline-block');
  }, 400);

  setTimeout(function () {
    $('.section4_img2').css('display', 'inline-block');
  }, 800);

  return (
    <Div100vh>
      <div
        id="ContentWrap"
        className="col-12 d-flex flex-row justify-content-center align-items-center overflow-hidden"
      >
        <div
          id="cntWrap2"
          className="d-flex justify-content-center align-items-center"
        >
          <div className="d-flex flex-column mb-3">
            <p className="sectionMiniTitle">자동급여계산서비스</p>
            <h2 className="sectionTitle">
              예상 지출액 확인과
              <br />
              편리한 급여 관리
            </h2>
            <p className="sectionText">
              출퇴근시간 데이터를 토대로
              <br />
              각종 세액 금액이 자동 반영됩니다.
              <br />
            </p>
          </div>
          <div
            id="payImgWrap"
            className="d-flex flex-row justify-content-center align-items-center"
          >
            <div className="itemWrap">
              <img
                className="itemImg section4_img1 animate__animated animate__pulse"
                alt="기능소개이미지"
                src={SectionImg1}
              />
              <img
                className="itemImg section4_img2 animate__animated animate__pulse"
                alt="기능소개이미지"
                src={SectionImg2}
              />
            </div>
            <img
              className="section_img4"
              alt="기능소개이미지"
              src={SectionImg}
            />
          </div>
        </div>
      </div>
    </Div100vh>
  );
}

export default L04_Section02;
