import React from 'react';
import styled from 'styled-components';
import Form from './Form';
import Logo from './Logo';
import device from '../../Styles/Device';
import Meta from '../MetaTag'

const Wrap = styled.div`
    padding: 3% 0 5%;
    height:100vh;
    overflow-x: auto;
    background-color: #f7f7f7;
    @media ${device.phone} {
        padding: 10% 10% 20% 10%;
    }
`;

export default () => {
    return (
    <>
        <Meta />
        <Wrap>
            <Logo />
            <Form />
        </Wrap>
    </>
    );
};