import React from 'react';
import './css/L07_Review.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Div100vh from 'react-div-100vh';

function L07_Review() {
  let settings = {
    className: 'center',
    centerMode: true, // 메인 아이템이 정가운데에 오게 할 것인지
    dots: true, // 캐러셀 점 보여줄 것인지
    infinite: true, // 마지막 다음 첫번째 보이게 할 것인지
    speed: 500, // 넘어가는 속도
    slidesToShow: 2, // 한번에 몇 칸 보여줄 것인지
    slidesToScroll: 1, // 스크롤 시 얼만큼 이동할 것인지
    autoplay: true, // 자동 전환 여부
    autoplaySpeed: 3000, // 자동 전환 속도
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 539,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Div100vh>
      <div className="col-12 d-flex flex-column justify-content-center align-items-center reviewWrap">
        <h2 className="reviewTitle col-12 text-center">
          <b>샵솔</b>을 이용한 유저들이 남긴 후기!
        </h2>
        <div className="reviewCnt col-xl-10 col-lg-10 col-md-11 col-sm-11">
          <Slider {...settings}>
            <div className="slickBox">
              <div className="reviewBox">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row align-items-center">
                    <p className="star">★★★★★</p>
                    <p className="starNum">5</p>
                  </div>
                  <p className="reviewText">
                    <b>직원관리앱</b>이 뭔가 신기해서
                    <br />
                    다운 받아봤는데 <b>한눈에 볼수있고</b>
                    <br />
                    깔끔한 인터페이스에
                    <br />
                    예전처럼 회사에 다 설치해서 하는게 아니라
                    <br />
                    <b>자동으로 되어서</b> 더 편리하고 괜찮은것같아요
                    <br />
                    우연치않게 다운 받아서 써봤는데 너무 좋네요.
                  </p>
                </div>
              </div>
            </div>
            <div className="slickBox">
              <div className="reviewBox">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row align-items-center">
                    <p className="star">★★★★★</p>
                    <p className="starNum">5</p>
                  </div>
                  <p className="reviewText">
                    제가 일한 시간을 볼 수 있어서 너무 좋고,
                    <br />
                    <b>출근 퇴근을 찍는게 간편하고 쉬워서</b> 너무 좋습니다!
                    <br />
                    예전보다 업그레이드 되어서
                    <br />
                    디자인도 깔끔하고 보기에도 좋고
                    <br />
                    <b>사장님이 바로바로 근무시간을 확인 가능</b>하니까
                    <br />
                    따로 시간조율 안해도 되서 좋아요~
                  </p>
                </div>
              </div>
            </div>
            <div className="slickBox">
              <div className="reviewBox">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row align-items-center">
                    <p className="star">★★★★★</p>
                    <p className="starNum">5</p>
                  </div>
                  <p className="reviewText">
                    처음사용해보는건데 신기하고
                    <br />
                    <b>근무시간이 일정치않은 부분을 정확하게 기록</b>할수있어서
                    좋아요
                    <br />
                    그리고 <b>근무일정체크</b>도 할수있고
                    <br />
                    <b>할일들도 적어놓을수있고</b>
                    <br />
                    편리한 앱인것같아요
                    <br />
                    수기로작성하기 귀찮은 부분이 많아서
                    <br />
                    다른가게도 샵솔을 썼으면 좋겠습니다
                  </p>
                </div>
              </div>
            </div>
            <div className="slickBox">
              <div className="reviewBox">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row align-items-center">
                    <p className="star">★★★★★</p>
                    <p className="starNum">5</p>
                  </div>
                  <p className="reviewText">
                    <b>직원 출퇴근 기능</b>이 많이 도움되고,
                    <br />
                    <b>업무전달시 푸쉬알림</b>이 가니
                    <br />
                    모두들 <b>실시간</b>으로 쉽게 확인 가능합니다.
                    <br />
                    좋은 어플 감사합니다.
                  </p>
                </div>
              </div>
            </div>
            <div className="slickBox">
              <div className="reviewBox">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row align-items-center">
                    <p className="star">★★★★★</p>
                    <p className="starNum">5</p>
                  </div>
                  <p className="reviewText">
                    <b>급여계산이 생각보다 자세히</b> 나와서 놀랐구요.
                    <br />
                    제일 좋은점은 아무래도 직원들 지각이 많이 줄어들었네요..
                    <br />
                    <b>직원들이 본인들이 늦는지 안늦는지 아니까</b>
                    <br />
                    5분씩 지각했던 친구들이 다 제시간에 오네요ㅎㅎ
                    <br />
                    앞으로 잘 이용해봐야겠어요
                    <br />
                    감사합니다~~
                  </p>
                </div>
              </div>
            </div>
            <div className="slickBox">
              <div className="reviewBox">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row align-items-center">
                    <p className="star">★★★★★</p>
                    <p className="starNum">5</p>
                  </div>
                  <p className="reviewText">
                    이런거를 회사들어와서 처음 시도하는건데
                    <br />
                    저는 개인적으로{' '}
                    <b>위치기반의 출퇴근 관리시스템이 잘 되어있어</b>
                    <br />
                    직원관리에도 상당히 효율적일것 같습니다.
                    <br />
                    또한 <b>업무체크리스트</b> 기능이 있어
                    <br />
                    깜빡하기쉬운 데이리스트를 빠짐없이 체크할수있어
                    <br />
                    그점또한 너무좋은것같습니다.
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </Div100vh>
  );
}

export default L07_Review;
