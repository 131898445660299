import React, { useState } from 'react';
import styled from 'styled-components';
import device from '../../Styles/Device';
import Meta from '../MetaTag';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: auto;
  background-color: white;

  @media ${device.tablet} {
    align-items: flex-start;
  }
  @media ${device.phone} {
    align-items: flex-start;
  }
`;
const Inner = styled.div`
  display: flex;
  max-width: 1100px;
  width: 40%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    width: 90%;
  }
  @media ${device.phone} {
    width: 90%;
  }
`;
const LogoArea = styled.div`
  display: flex;
  padding: 10% 0;
  justify-content: center;
`;
const Logo = styled.img`
  width: 30%;
  object-fit: contain;
`;
const MainArea = styled.div`
  display: flex;
  width: 100%;
`;
const Main = styled.img`
  width: 100%;
`;
const BoxArea = styled.div`
  display: flex;
  padding-bottom: 5%;
  width: 100%;
  flex-wrap: wrap;
`;
const BoxInner = styled.a`
  margin-top: 2%;
  width: 49%;

  &:nth-child(2n + 1) {
    margin-right: 2%;
  }
`;
const BoxImage = styled.img`
  width: 100%;
`;

export default (props) => {
  const Location = props.location.pathname;

  let Menu = [];
  if (Location.includes('/appCummunity/employee')) {
    Menu = [
      {
        Img: 'cummunity',
        Link: 'http://serverapi.custaffhr.com:82/shopsol_employee/',
      },
      { Img: 'info', Link: 'https://bit.ly/3beMBiF' },
    ];
  }
  if (Location.includes('/appCummunity/owner')) {
    Menu = [
      {
        Img: 'cummunity',
        Link: 'http://serverapi.custaffhr.com:82/shopsol_employer',
      },
      { Img: 'marketing', Link: 'https://bit.ly/3jDViaI' },
      { Img: 'semunomu', Link: 'https://bit.ly/3mhlZDS' },
      { Img: 'info', Link: 'https://bit.ly/3jClP8j' },
    ];
  }

  const Box = ({ data }) => {
    console.log('data', data);
    return (
      <BoxInner href={data.Link}>
        <BoxImage src={require(`../../images/appCummunity/${data.Img}.png`)} />
      </BoxInner>
    );
  };

  return (
    <>
      <Meta />
      <Container>
        <Inner>
          <LogoArea>
            <Logo src={require('../../images/appCummunity/logo.png')} />
          </LogoArea>
          <MainArea>
            {Location.includes('/appCummunity/owner') && (
              <Main src={require(`../../images/appCummunity/main_owner.png`)} />
            )}
            {Location.includes('/appCummunity/employee') && (
              <Main
                src={require(`../../images/appCummunity/main_employeepng.png`)}
              />
            )}
          </MainArea>
          <BoxArea>
            {Menu.map((item, index) => {
              return <Box data={item} key={index} />;
            })}
          </BoxArea>
        </Inner>
      </Container>
    </>
  );
};
