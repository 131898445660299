import React, { useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '../../Styles/Modal';
import device from '../../Styles/Device';

const styles = {
  WrapInner: {
    borderWidth: 1,
  },
  menu: {
    padding: '20px',
    position: 'relative',
    overflow: 'hidden',
    width: '1000px',
  },
  policyArea: {},
  articleArea: {},
  policyAll: {},
  checkImage: {
    marginRight: 7,
    width: 40,
    fill: '#4475ee',
  },
  policyAtc: {
    marginTop: 30,
  },
  policyTitle: {
    fontSize: 14,
    lineHeight: 1.62,
    letterSpacing: 1,
    fontFamily: 'Noto Sans KR',
  },
  policyCnt: {},
  btnArea: {
    textAlign: 'center',
    marginTop: 40,
  },
};

const Wrap = styled.div`
  display: flex;
  margin: 0 auto;
  width: 420px;
  flex-direction: column;
  @media ${device.phone} {
    width: auto;
  }
`;
const PolicyAgree = styled.button`
  display: flex;
  font-size: 20px;
  border: 0;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  :hover {
    strong {
      text-decoration: underline;
    }
  }
`;
const Label = styled.label`
  font-family: 'Noto Sans KR';
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 1.62;
  letter-spacing: 1px;
`;
const Input = styled.input`
  font-family: 'Noto Sans KR';
  font-size: 12px;
  width: 100%;
  text-indent: 10px;
  color: #000;
  line-height: 45px;
  letter-spacing: 1px;
  border: ${(props) =>
    props.inputStatus == 'error'
      ? '2px solid #bc1a21'
      : props.inputStatus == 'complate'
      ? '1px solid #000'
      : '1px solid #b2b1b1'};
  background-color: #fff;
  outline-style: none;
  ::placeholder {
    color: #acacac;
    font-family: 'Noto Sans KR';
  }
`;

const PolicyCnt = styled.div`
  margin-top: 5px;
  overflow-x: auto;
  padding: 10px;
  height: 100px;
  font-size: 13px;
  border: 0.8px solid #b2b1b1;
  background-color: #fff;
  white-space: pre-wrap;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #eee;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
`;
const FormArea = styled.div``;
const PhoneCertification = styled.button`
  font-family: 'Noto Sans KR';
  margin-left: 10px;
  width: 120px;
  color: white;
  border: 0px;
  font-size: 12px;
  letter-spacing: 0.65px;
  background-color: rgb(0, 155, 134);
  border-radius: 5px;
  cursor: pointer;
  :hover {
    background-color: #444;
  }
  @media ${device.phone} {
    font-size: 11px;
  }
`;
const ManagerPage = styled.div`
  margin-top: 10%;
  text-align: center;
`;
const ManagerPageBtn = styled.a`
  margin: auto;
  color: rgb(58, 137, 255);
  font-size: 14px;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  :hover {
    text-decoration: underline;
  }
`;
const JoinBtn = styled.button`
  font-family: 'Noto Sans KR';
  margin-top: 30px;
  width: 100%;
  line-height: 50px;
  font-size: 15px;
  color: #fff;
  letter-spacing: 9.1px;
  background-color: rgb(0, 155, 134);
  cursor: pointer;
  border-radius: 5px;
  :hover {
    background-color: #444;
  }
`;
const Section = styled.div`
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;
`;
const Title = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 17px;
  letter-spacing: 8.5px;
  text-align: center;
`;
const Text = styled.strong`
  font-size: 22px;
  font-family: 'Do Hyeon';
`;
const BackBtn = styled.button`
  margin-bottom: 20px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #eee;
  border: 0px;
  cursor: pointer;
  :hover {
    background-color: #e2e2e2;
  }
`;
const Arrow = styled.img`
  width: 20px;
  height: 20px;
`;
const ErrorText = styled.strong`
  display: block;
  padding-left: 5px;
  color: rgb(0, 155, 134);
  font-size: 0.8em;
`;

const PolicyNext = styled.button`
  font-family: 'Noto Sans KR';
  border-radius: 5px;
  width: 100%;
  height: 50px;
  color: #fff;
  font-size: 13px;
  line-height: 1.62;
  letter-spacing: 9.1px;
  background-color: rgb(0, 155, 134);
  cursor: pointer;
  :hover {
    background-color: #444;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
      fontFamily: 'Noto Sans KR',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '0',
    },
    '& .MuiOutlinedInput-input': {
      width: '100%',
      padding: '15px 14px',
      fontSize: '12px',
      color: '#b2b1b1',
      backgroundColor: 'white',
      fontFamily: 'Noto Sans KR',
    },
  },
  ComplateInput: {
    '& .MuiOutlinedInput-input': {
      fontFamily: 'Noto Sans KR',
      color: '#000',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      fontFamily: 'Noto Sans KR',
      borderColor: '#000',
    },
  },
}));

const ROUTES = [
  { value: '가입경로를 선택하세요.' },
  { value: '광고배너' },
  { value: '네이버' },
  { value: '블로그/카페' },
  { value: '유투브' },
  { value: '지인소개' },
  { value: '기타' },
];

const BIRTH_M = [
  { value: '월선택' },
  { value: '01' },
  { value: '02' },
  { value: '03' },
  { value: '04' },
  { value: '05' },
  { value: '06' },
  { value: '07' },
  { value: '08' },
  { value: '09' },
  { value: '10' },
  { value: '11' },
  { value: '12' },
];

const BIRTH_D = [
  { value: '일선택' },
  { value: '01' },
  { value: '02' },
  { value: '03' },
  { value: '04' },
  { value: '05' },
  { value: '06' },
  { value: '07' },
  { value: '08' },
  { value: '09' },
  { value: '10' },
  { value: '11' },
  { value: '12' },
  { value: '13' },
  { value: '14' },
  { value: '15' },
  { value: '16' },
  { value: '17' },
  { value: '18' },
  { value: '19' },
  { value: '20' },
  { value: '21' },
  { value: '22' },
  { value: '23' },
  { value: '24' },
  { value: '25' },
  { value: '26' },
  { value: '27' },
  { value: '28' },
  { value: '29' },
  { value: '30' },
  { value: '31' },
];

const GENDER = [
  { value: '성별을 선택하세요.' },
  { value: '남자' },
  { value: '여자' },
];

const EMAIL = [
  { value: '직접입력' },
  { value: 'hanmail.net' },
  { value: 'naver.com' },
  { value: 'nate.com' },
  { value: 'korea.com' },
  { value: 'gmail.com' },
  { value: 'yahoo.co.kr' },
];

export default () => {
  const [state, setState] = React.useState({
    marginLeft: 0,
    policyCheck: false, // 정책 동의 페이지 이동
    policyAllAgree: false, // 정책 동의 체크
    id: '', // 휴대폰번호
    ctfCheck: false, // 인증요청 여부
    ctfNum: '', // 인증코드
    ctfComplateCheck: false, // 인증완료 여부
    pwd: '', // 비밀번호
    pwdCheck: '', // 비밀번호확인
    pwdText: false, // 비밀번호 텍스트 표시 여부
    pwdCheckText: false, // 비밀번호확인 텍스트 표시 여부
    name: '', // 이름
    birthday_y: '', // 년도
    birthday_m: '월선택', // 월자
    birthday_d: '일선택', // 일자
    gender: '성별을 선택하세요.', // 성별
    email_name: '', // 앞
    email_name_check: '', // 앞
    email_addr: '', // 뒤
    email_addr_check: '', // 뒤
    email_type: '직접입력', // 뒤 형식
    route: '가입경로를 선택하세요.', // 가입경로
    other_route: '', // 기타 가입경로
    focusing: false, // 휴대폰번호 포커싱
    focusing1: false, // 본인인증 포커싱
    focusing2: false, // 비밀번호 포커싱
    allCheck: false, // 모든항목 입력체크
    modalVisible: false, // alert view
    modalText: '', // 모달 텍스트
  });

  const onSubmit1 = async (e) => {
    e.preventDefault();
    var regExp = /(01[016789])([1-9]{1}[0-9]{2,3})([0-9]{4})$/;
    if (regExp.test(state.id)) {
      // if (regExp.test(state.id)) {
      try {
        let response = await fetch(
          'https://shopsolwebserver.shop-sol.com/api/auth/getsms',
          // 'https://rvcg2s1cvh.execute-api.ap-northeast-2.amazonaws.com/shopsol_homepage/getsms',
          // 'https://api-gw.cloud.toast.com/sms/getsms',
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              MOBILENO: state.id.toString(),
            }),
          },
        );
        const json = await response.json();
        // console.log('json', json);
        if (json.RESULT_CODE == 1) {
          setState({
            ...state,
            modalVisible: true,
            modalText: '이미 가입된 번호입니다.',
          });
          // alert(
          //   '이미 가입된 번호입니다.',
          // );
          return;
        }
        if (json.RESULT_CODE == 0) {
          setState({
            ...state,
            ctfCheck: true,
            modalVisible: true,
            modalText:
              '인증번호가 sms로 발송되었습니다 인증번호를 입력해주세요\n통신사 스팸 또는 웹발신 거부 서비스에 가입되어있는 경우 문자메시지 수신이 불가합니다',
            ctfComplateCheck: false,
          });
        }
      } catch (error) {
        // console.log(error);
      }
    } else {
      setState({
        ...state,
        modalVisible: true,
        modalText: '올바른 휴대폰번호를 입력해 주세요.',
      });
      return;
    }
  };

  const onSubmit2 = async (e) => {
    e.preventDefault();
    if (state.ctfCheck == false) {
      setState({
        ...state,
        modalVisible: true,
        modalText: '휴대폰번호 인증을 요청해 주세요.',
      });
      // alert('휴대폰번호 인증을 요청해주세요');
    } else {
      try {
        let response = await fetch(
          'https://shopsolwebserver.shop-sol.com/api/auth/checksms',
          // 'https://api-gw.cloud.toast.com/sms/checksms',
          // 'http://shopsolapi.shop-sol.com:3002/api/auth/checksms',
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              MOBILENO: state.id.toString(),
              SMSNUMBER: state.ctfNum.toString(),
            }),
          },
        );
        const json = await response.json();
        // console.log(json);
        if (json.RESULT_CODE == 0) {
          setState({
            ...state,
            ctfComplateCheck: true,
            modalVisible: true,
            modalText: '휴대폰이 인증되었습니다.',
          });
          // alert('휴대폰이 인증되었습니다.');
        } else {
          setState({
            ...state,
            modalVisible: true,
            modalText: '인증번호가 맞지않습니다.',
          });
          return;
          // alert('인증번호가 맞지않습니다.');
        }
      } catch (error) {
        // console.log(error);
      }
    }
  };

  const _regist = async (e) => {
    e.preventDefault();
    // console.log('33e', e);
    const BIRTHDATE = `${state.birthday_y}-${state.birthday_m}-${state.birthday_d}`;
    const GENDER = state.gender == '남자' ? '1' : '0';
    const EMAIL = `${state.email_name}@${state.email_addr}`;
    var CATEGORY;
    if (state.route == '광고배너') {
      CATEGORY = '1';
    } else if (state.route == '네이버') {
      CATEGORY = '2';
    } else if (state.route == '블로그/카페') {
      CATEGORY = '3';
    } else if (state.route == '유투브') {
      CATEGORY = '4';
    } else if (state.route == '지인소개') {
      CATEGORY = '5';
    } else if (state.route == '기타') {
      CATEGORY = '6';
    }

    try {
      let response = await fetch(
        'https://shopsolwebserver.shop-sol.com/api/auth/signup',
        // 'https://api-gw.cloud.toast.com/sms/signup',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            SMSNUMBER: state.ctfNum, // 인증번호
            MOBILENO: state.id,
            PASSWORD: state.pwd,
            NAME: state.name,
            BIRTHDATE: BIRTHDATE,
            GENDER: GENDER,
            EMAIL: EMAIL,
            STORE: '1',
            CATEGORY: CATEGORY,
            other: state.other_route,
          }),
        },
      );
      const json = await response.json();
      // console.log(json);

      if (json.message === 'ALREADY_SUCCESS') {
        // alert('이미 가입한 휴대폰번호입니다');
        setState({
          ...state,
          modalVisible: true,
          modalText: '이미 가입한 휴대폰번호입니다.',
        });
      } else if (json.message === 'SMSERROR') {
        // alert('인증번호 오류입니다');
        setState({
          ...state,
          modalVisible: true,
          modalText: '인증번호 오류입니다.',
        });
      } else {
        setState({
          ...state,
          modalVisible: true,
          modalText: '회원가입이 완료되었습니다 로그인을 진행해 주세요.',
        });

        const today = new Date();
        const year = today.getFullYear();
        const month = ('0' + (today.getMonth() + 1)).slice(-2);
        const day = ('0' + today.getDate()).slice(-2);
        const dateString = year + '-' + month + '-' + day;

        dataLayer.push({
          event: 'signup_complete',
          signup_date: dateString,
          signup_method:
            state.route !== '기타'
              ? state.route
              : `${state.route}(${state.other_route})`,
          signup_birthday: BIRTHDATE,
          signup_gender: state.gender,
        });

        setTimeout(() => {
          window.location.href = 'http://ceo.shop-sol.com';
          // window.location.href = 'http://ceo1.shop-sol.com/sub/manager_sub(login).html';
        }, 3000);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const textChange = (e) => {
    if (e.target.name == 'id') {
      setState({ ...state, [e.target.name]: e.target.value.replace(/\D/, '') });
    } else if (e.target.name == 'pwd') {
      const pwd = e.target.value;
      var num = pwd.search(/[0-9]/g);
      var eng = pwd.search(/[a-z]/gi);
      if (num !== -1 && eng !== -1 && pwd.length >= 6) {
        setState({
          ...state,
          [e.target.name]: e.target.value,
          pwdCheck: '',
          pwdText: false,
          pwdCheckText: false,
        });
      } else {
        setState({
          ...state,
          [e.target.name]: e.target.value,
          pwdCheck: '',
          pwdText: true,
          pwdCheckText: false,
        });
      }
    } else if (e.target.name == 'pwdCheck') {
      const { pwd } = state;
      if (pwd == e.target.value) {
        setState({
          ...state,
          [e.target.name]: e.target.value,
          pwdCheckText: false,
        });
      } else {
        setState({
          ...state,
          [e.target.name]: e.target.value,
          pwdCheckText: true,
        });
      }
    } else if (e.target.name == 'email_name') {
      var reg = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])/i;
      var nameCheck = reg.test(e.target.value);
      if (nameCheck) {
        setState({
          ...state,
          email_name_check: true,
          email_name: e.target.value,
        });
      } else {
        setState({
          ...state,
          email_name_check: false,
          email_name: e.target.value,
        });
      }
    } else if (e.target.name == 'email_addr') {
      var reg = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
      var addrCheck = reg.test(e.target.value);
      if (addrCheck) {
        setState({
          ...state,
          email_addr_check: true,
          email_addr: e.target.value,
        });
      } else {
        setState({
          ...state,
          email_addr_check: false,
          email_addr: e.target.value,
        });
      }
    } else if (e.target.name == 'route') {
      if (e.target.value !== '기타') {
        setState({
          ...state,
          [e.target.name]: e.target.value,
          other_route: '',
        });
      } else {
        setState({ ...state, [e.target.name]: e.target.value });
      }
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };

  const handleChangeBirth = (event) => {
    if (event.target.name == 'birthday_y') {
      setState({
        ...state,
        [event.target.name]: event.target.value.replace(/\D/, ''),
      });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };
  const handleChangeGender = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };
  const handleChangeEmail = (event) => {
    if (event.target.value == '직접입력') {
      setState({
        ...state,
        [event.target.name]: event.target.value,
        email_addr: '',
        email_addr_check: true,
      });
    } else {
      setState({
        ...state,
        [event.target.name]: event.target.value,
        email_addr: event.target.value,
        email_addr_check: true,
      });
    }
  };

  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // console.log('state----------', state);
    if (
      state.ctfComplateCheck && // 인증완료 여부
      state.pwd !== '' && // 비밀번호
      !state.pwdText && // 비밀번호 형식체크
      state.pwdCheck !== '' && // 비밀번호 확인
      !state.pwdCheckText && // 비밀번호 확인체크
      state.name !== '' && // 이름
      state.birthday_y.length == 4 && // 생년월일 년도 (4자리)
      state.birthday_m !== '월선택' && // 생년월일 월자
      state.birthday_d !== '일선택' && // 생년월일 일자
      state.gender !== '성별을 선택하세요.' && // 성별체크 여부
      state.email_name !== '' && // 이메일 앞자리 작성여부
      state.email_name_check && // 이메일 앞자리 체크
      state.email_addr !== '' && // 이메일 뒷주소 작성여부
      state.email_addr_check && // 이메일 뒷주소 체크
      state.route !== '가입경로를 선택하세요.'
    ) {
      // 기타가입경로 체크
      setState({ ...state, allCheck: true });
    } else {
      setState({ ...state, allCheck: false });
    }
  }, [
    state.ctfComplateCheck,
    state.pwd,
    state.pwdText,
    state.pwdCheck,
    state.pwdCheckText,
    state.name,
    state.birthday_y,
    state.birthday_m,
    state.birthday_d,
    state.gender,
    state.email_name,
    state.email_name_check,
    state.email_addr,
    state.email_addr_check,
    state.route,
  ]);

  return (
    <Wrap>
      <Title>
        <Text>회원가입</Text>
      </Title>
      {!state.policyCheck ? (
        <div style={styles.policyArea}>
          <div style={styles.articleArea}>
            <div style={styles.policyAll}>
              <PolicyAgree
                style={state.policyAllAgree ? {} : { color: '#888' }}
                onClick={() => {
                  setState({
                    ...state,
                    policyAllAgree: !state.policyAllAgree,
                  });
                  if (!state.policyAllAgree) {
                    window.scrollTo({
                      top: window.innerHeight,
                      behavior: 'smooth',
                    });
                  }
                }}
              >
                {state.policyAllAgree ? (
                  <svg
                    width="40px"
                    height="40px"
                    viewBox="0 0 16 16"
                    className="bi bi-check-circle"
                    fill="rgb(0, 155, 134)"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>
                ) : (
                  // <CgCheckO style={{ width: 70, height: 70, color: 'black', }}/>
                  <svg
                    width="40px"
                    height="40px"
                    viewBox="0 0 16 16"
                    className="bi bi-check-circle"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>
                )}
                <span
                  style={{
                    marginLeft: 20,
                    textAlign: 'left',
                    fontSize: 16,
                    lineHeight: 1.62,
                    letterSpacing: '0.51px',
                    fontFamily: 'Noto Sans KR',
                  }}
                >
                  샵솔의{' '}
                  <strong style={styles.underline}>서비스 이용약관</strong>,{' '}
                  <strong style={styles.underline}>개인정보처리방침</strong>,{' '}
                  <strong style={styles.underline}>위치정보 제공</strong>에 모두
                  동의합니다.
                </span>
              </PolicyAgree>
            </div>
            <div style={styles.policyAtc}>
              <strong style={styles.policyTitle}>서비스 이용약관</strong>
              <PolicyCnt>{TermPolicy()}</PolicyCnt>
            </div>
            <div style={styles.policyAtc}>
              <strong style={styles.policyTitle}>개인정보처리방침</strong>
              <PolicyCnt>{PrivacyPolicy()}</PolicyCnt>
            </div>
            <div style={styles.policyAtc}>
              <strong style={styles.policyTitle}>위치정보 제공</strong>
              <PolicyCnt>{LocationPolicy()}</PolicyCnt>
            </div>
          </div>
          <div style={styles.btnArea}>
            <PolicyNext
              CHECK={state.policyAllAgree}
              disabled={!state.policyAllAgree}
              onClick={() => {
                setState({ ...state, policyCheck: true });
              }}
            >
              다음단계로
            </PolicyNext>
          </div>
        </div>
      ) : (
        <div style={styles.WrapInner}>
          <FormArea>
            <form onSubmit={onSubmit1}>
              <Section>
                <Label>휴대폰번호 (ID로 사용하게 됩니다.)</Label>
                <div style={{ display: 'flex' }}>
                  <Input
                    type="text"
                    placeholder={'번호를 입력하세요.'}
                    value={state.id}
                    onChange={textChange}
                    name="id"
                    onFocus={() => {
                      setState({ ...state, focusing: true });
                    }}
                    onBlur={() => {
                      setState({ ...state, focusing: false });
                    }}
                    maxLength="11"
                    style={{ height: '53px' }}
                  />
                  <PhoneCertification disabled={state.ctfComplateCheck}>
                    인증번호 받기
                  </PhoneCertification>
                </div>
              </Section>
            </form>
            <form onSubmit={onSubmit2}>
              <Section>
                <Label>휴대폰인증</Label>
                <div style={{ display: 'flex' }}>
                  <Input
                    type="text"
                    placeholder={'인증번호를 입력하세요.'}
                    value={state.ctfNum}
                    onChange={textChange}
                    name="ctfNum"
                    onFocus={() => {
                      setState({ ...state, focusing1: true });
                    }}
                    onBlur={() => {
                      setState({ ...state, focusing1: false });
                    }}
                    maxLength="6"
                    disabled={state.ctfComplateCheck}
                    style={{ height: '53px' }}
                  />
                  <PhoneCertification disabled={state.ctfComplateCheck}>
                    {state.ctfComplateCheck ? '인증완료' : '인증하기'}
                  </PhoneCertification>
                </div>
              </Section>
            </form>
            <form className={classes.root}>
              <Section>
                <Label>비밀번호</Label>
                <Input
                  inputStatus={
                    state.pwdText ? 'error' : state.pwd !== '' ? 'complate' : ''
                  }
                  placeholder={'비밀번호를 입력하세요.'}
                  value={state.pwd}
                  onChange={textChange}
                  name="pwd"
                  type="password"
                  onFocus={() => {
                    setState({ ...state, focusing2: true });
                  }}
                  onBlur={() => {
                    setState({ ...state, focusing2: false });
                  }}
                  maxLength={15}
                  style={{ height: '53px' }}
                />
                {state.pwdText ? (
                  <ErrorText>
                    * 비밀번호는 숫자와 영문자를 혼용하여 6~15자리를 입력해
                    주세요.
                  </ErrorText>
                ) : null}
              </Section>
              <Section>
                <Label>비밀번호 확인</Label>
                <Input
                  inputStatus={
                    state.pwdCheckText
                      ? 'error'
                      : state.pwdCheck !== ''
                      ? 'complate'
                      : ''
                  }
                  placeholder={'비밀번호를 재입력하세요.'}
                  value={state.pwdCheck}
                  onChange={textChange}
                  name="pwdCheck"
                  type="password"
                  maxLength={15}
                  style={{ height: '53px' }}
                />
                {state.pwdCheckText ? (
                  <ErrorText>* 비밀번호가 일치하지 않습니다</ErrorText>
                ) : null}
              </Section>
              <Section>
                <Label>이름</Label>
                <Input
                  inputStatus={state.name !== '' ? 'complate' : ''}
                  placeholder={'이름을 입력하세요.'}
                  type="text"
                  value={state.name}
                  onChange={textChange}
                  name="name"
                  maxLength="15"
                  style={{ height: '53px' }}
                />
              </Section>
              <Section>
                <Label>생년월일</Label>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      display: 'felx',
                      flex: 1,
                    }}
                  >
                    <Input
                      inputStatus={
                        state.birthday_y.length == 4 ? 'complate' : ''
                      }
                      placeholder={'년도입력'}
                      type="text"
                      value={state.birthday_y}
                      onChange={handleChangeBirth}
                      name="birthday_y"
                      maxLength={4}
                      fontSize={12}
                      style={{ height: '53px' }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'felx',
                      flex: 1,
                      margin: 0,
                      padding: 0,
                      marginLeft: '10px',
                      fontFamily: 'Noto Sans KR',
                      fontSize: '12px',
                    }}
                  >
                    <TextField
                      className={
                        state.birthday_m
                          ? state.birthday_m == '월선택'
                            ? ''
                            : classes.ComplateInput
                          : ''
                      }
                      id="outlined-select-currency"
                      select
                      value={state.birthday_m}
                      onChange={handleChangeBirth}
                      variant="outlined"
                      name="birthday_m"
                    >
                      {BIRTH_M.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div style={{ display: 'felx', flex: 1, marginLeft: '10px' }}>
                    <TextField
                      className={
                        state.birthday_d
                          ? state.birthday_d == '일선택'
                            ? ''
                            : classes.ComplateInput
                          : ''
                      }
                      id="outlined-select-currency"
                      select
                      value={state.birthday_d}
                      onChange={handleChangeBirth}
                      variant="outlined"
                      name="birthday_d"
                    >
                      {BIRTH_D.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </Section>
              <Section>
                <Label>성별</Label>
                <TextField
                  className={
                    state.gender !== '성별을 선택하세요.'
                      ? classes.ComplateInput
                      : ''
                  }
                  id="outlined-select-currency"
                  select
                  value={state.gender}
                  onChange={handleChangeGender}
                  variant="outlined"
                  name="gender"
                >
                  {GENDER.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Section>
              <Section>
                <Label>이메일</Label>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <Input
                      inputStatus={
                        state.email_name !== ''
                          ? state.email_name_check
                            ? 'complate'
                            : 'error'
                          : ''
                      }
                      type="text"
                      value={state.email_name}
                      onChange={textChange}
                      name="email_name"
                      style={{ height: '53px' }}
                    />
                  </div>
                  <div style={{ padding: '0 5px' }}>@</div>
                  <div style={{ flex: 1 }}>
                    <Input
                      inputStatus={
                        state.email_addr !== ''
                          ? state.email_addr_check
                            ? 'complate'
                            : 'error'
                          : ''
                      }
                      type="text"
                      value={state.email_addr}
                      onChange={textChange}
                      name="email_addr"
                      disabled={state.email_type !== '직접입력'}
                      style={{ height: '53px' }}
                    />
                  </div>
                  <div style={{ flex: 1, marginLeft: '10px' }}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      value={state.email_type}
                      onChange={handleChangeEmail}
                      variant="outlined"
                      name="email_type"
                    >
                      {EMAIL.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </Section>
              <Section>
                <Label>가입경로</Label>
                <TextField
                  className={
                    state.route == '가입경로를 선택하세요.'
                      ? ''
                      : classes.ComplateInput
                  }
                  id="outlined-select-currency"
                  select
                  value={state.route}
                  onChange={textChange}
                  variant="outlined"
                  name="route"
                  style={{ height: '53px' }}
                >
                  {ROUTES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Section>
              <Section>
                <Label>
                  가입경로에서 '기타'를 선택하신 경우에만 입력해 주세요.
                </Label>
                <Input
                  placeholder={'기타 가입경로 입력하기'}
                  type="text"
                  value={state.other_route}
                  onChange={textChange}
                  name="other_route"
                  maxLength={30}
                  disabled={state.route !== '기타'}
                />
              </Section>
              <JoinBtn
                allCheck={state.allCheck}
                onClick={_regist}
                disabled={!state.allCheck}
              >
                가입하기
              </JoinBtn>
            </form>
          </FormArea>
        </div>
      )}
      {state.modalVisible && (
        <Modal
          visible={state.modalVisible}
          closable={true}
          maskClosable={true}
          onClose={() => setState({ ...state, modalVisible: false })}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <div style={{ padding: '20px 0' }}>{state.modalText}</div>
          </div>
        </Modal>
      )}
    </Wrap>
  );
};

const PrivacyPolicy = () => {
  const text = `개인정보취급방침
​
제 1 조 (총 칙)

개인정보란 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명，본인인증기관을 통해 인증된 개인식별 번호 등의 사항에 의하여 당해 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함합니다)를 말합니다.
1. 위솝(이하 '회사'라 함)은 정보주체의 개인정보보호를 중요시하며, 『정보 통신망 이용 촉진 및 정보보호에 관한 법률』상의 개인정보 보호 규정 및 『개인정보보호법』을 준수하고 있습니다. 회사는 개인정보 처리방침을 통하여 정보주체가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
2. 회사는 개인정보 처리방침을 사이트 첫 화면에 공개함으로써 이용자가 언제나 용이하게 볼 수 있도록 조치하고 있습니다.
3. 회사는 개인정보 처리방침의 지속적인 개선을 위하여 개인정보 처리방침을 개정하는데 필요한 절차를 정하고 있습니다. 그리고 개인정보 처리방침을 개정하는 경우 버전 번호 등을 부여하여 개정된 사항을 이용자가 쉽게 알아볼 수 있도록 합니다.
      ​
제 2조 (개인정보 수집 및 이용에 대한 동의)

회사는 이용자가 개인정보보호방침을 열람하는 절차를 마련함으로써 '동의함' 버튼을 누르면 개인정보 수집에 대해 동의한 것으로 간주합니다.
      ​
제 3 조 (개인정보 수집항목)

사이트에서는 별도의 회원가입 절차 없이 특정 유료 서비스를 제외한 대부분의 콘텐츠에 자유롭게 접근할 수 있으며, 회원제 서비스를 이용하시려면 필수항목을 입력하여야 하며 선택항목을 입력하지 않더라도 서비스 이용에 제한을 두지 않습니다.
가. 수집하는 개인정보의 항목
1. 회원가입 시 수집하는 항목
  1) 개인회원
  ① 필수항목 : 이름, 아이디, 비밀번호, 휴대폰번호, 이메일, 성별, 연령대
  ② 선택항목 : 이메일 수신 설정, SMS/MMS 수신 설정
  ③ 페이스북 등 외부 서비스와의 연동을 통해 이용자가 설정한 계정 정보
  2) 기업회원
  ① 필수항목 : 가입자명, 이름, 본인인증정보 또는 중복가입확인정보(DI), 아이디, 비밀번호, 휴대폰번호, 전화번호, 이메일, 기업형태, 사업자등록번호, 회사명, 회사주소
  ② 선택항목 : 이메일 수신 설정, 가입경로, 팩스번호, 홈페이지
2. 면접정보(구직) 등록시 수집하는 항목
  ① 필수항목 : 이름, 연락처(전화번호/휴대폰/이메일), 주소, 근무형태, 희망근무지, 희망근무일시, 성별
  ② 선택항목 : 사진, 사이트, 우편번호, 주소, 고용형태, 희망연봉, 희망근무지, 지원분야(업직종, 키워드), 자기소개서, 희망급여, 근무가능일, 경력사항, 취업우대사항(보훈대상, 취업보호대상, 장애여부, 병역사항, 고용지원금대상, 교육이수내용, 자격증, OA능력, 컴퓨터 능력, 각오한마디, 장점/강점)
3. 면접(구인)정보 등록 시 수집하는 항목
담당자의 정보는 채용 이외의 용도로 이용할 수 없으며, 신청하신 서비스의 원활한 이용과 정보도용 및 허위정보 게재로 인한 피해를 방지하기 회원가입 단계에서 사업자등록증의 정보를 확인하고 있습니다. 또한 다음의 정보를 수집합니다.담당자 이름, 연락처(전화번호/휴대폰번호), 이메일"
4. 유료 정보 이용 시 수집하는 항목
  1) 유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.
  2) 신용카드 결제 : 카드사명, 카드번호 등
  3) 은행계좌 이체 : 은행명, 계좌번호 등
  4) 휴대전화 결제 : 가입자명, 휴대폰번호, 결제승인번호 등
      ​
제 4 조 (개인정보의 수집목적 및 이용목적)

1. 본 서비스는 다음과 같은 목적을 위하여 개인정보를 수집하고 있습니다.
  1) 회원제 서비스 이용에 따른 본인 식별 절차에 이용: 성명, 아이디, 본인인증기관을 통해 인증된 개인식별 번호
  2) 고지 사항 전달, 본인 의사 확인, 불만 처리 등 원활한 의사 소통 경로의 확보, 새로운 서비스/신상품이나 이벤트 정보의 안내: 이메일 주소, 전화번호
  3) 본인 인증 및 유료 정보 이용에 대한 요금 결제: 본인인증기관을 통해 인증된 개인식별 번호, 신용 카드 정보, 은행 계좌 정보, 전화번호, 휴대폰 번호
  4) 마케팅, 인구 통계학적 분석 자료 (이용자의 연령별, 성별, 지역별 통계 분석): 주소, 성별, 생년월일
  5) 이력서 작성 및 구직 활동 경력 사항: 생년월일, 성별, 전화번호, 주소, 학력, 경력, 자기 소개서
2. 단, 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보 (민족, 사상, 출신지 및 본적지, 정치적 성향 및 범죄 기록, 건강 상태 및 성생활 등)는 수집하지 않습니다.
​
제 5 조 (개인정보의 이용기간 및 보유기간)

이용자가 회사의 회원으로서 회사에서 제공하는 서비스를 이용하는 동안에 한하여 회사는 이용자의 개인정보를 보유 및 이용하며, 이용자가 회원탈퇴를 요청하거나, 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우 및 개인정보의 수집목적 또는 제공받은 목적이 달성되거나 이용기간이 종료된 경우, 개인정보를 지체 없이 파기 합니다. 또한, 이용자가 회사에서 제공하는 서비스에 1년간 로그인 등 이용 기록이 없을 경우에는 회사는 이용자의 계정을 휴면계정처리 하여 개인정보를 분리·저장하고 해당 이용자의 서비스 이용을 제한할 수 있으며, 이용자가 직접 본인확인을 거쳐 서비스 이용의사를 표시 한 경우에는 서비스이용이 가능합니다. 이 경우 휴면계정처리 예정일로부터 30일 이전에 해당 사실을 전자메일, 서면, SMS 중 하나의 방법으로 이용자에게 사전 통지하고 이용자가 로그인 등으로 서비스 이용의사표시를 한 경우에는 이용의사표시 일로부터 1년간 이용자의 계정을 휴면계정처리 하지 않습니다.
또한, 상법, 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계 법령에서 정한 일정한 기간 동안 이용자 정보를 보관합니다.
이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존 기간은 다음과 같습니다.
1. 관련 법령에 의한 정보보호 사유
  1) 계약 또는 청약 철회 등에 관한 기록 : 5년 (전자상거래 등에서의 소비자 보호에 관한 법률)
  2) 대금 결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래 등에서의 소비자 보호에 관한 법률)
  3) 소비자의 불만 또는 분쟁 처리에 관한 기록 : 3년 (전자상거래 등에서의 소비자 보호에 관한 법률)
  4) 표시/ 광고에 관한 기록 : 6개월 (전자상거래 등에서의 소비자에 관한 법률)
  5) 서비스 이용기록, 접속로그, 접속 IP 정보 : 3개월 (통신비밀보호법)
2. 내부 방침에 의한 정보보호 사유
  1) 보존 항목: 회원아이디, 임시 아이디, 개인식별 번호
  2) 보존 근거: 서비스 이용의 혼선 방지
  3) 보존 기간: 서비스 종료 시까지
​
제 6 조 (개인정보의 열람, 수정 및 삭제)

1. 정보주체가 제공한 개인정보의 열람, 수정 및 삭제는 언제든지 가능합니다. 회원 탈퇴 또한 사이트 상단에 있는 "정보수정"을 통하여 언제든지 자유롭게 가능합니다. 또한, 고객센터를 통해 이메일, 전화, FAX 등으로 연락을 주시면 본인 확인 절차를 거친 후 바로 조치하겠습니다.
2. 회사는 이용자가 회원 탈퇴를 하고 개인정보를 파기하는 등의 조치를 취한 경우에는 그 사실을 이용자에게 지체 없이 통지하도록 하겠습니다.
3. 잘못된 정보를 제3자에게 이미 제공한 경우에는 정정 처리 결과를 제3자에게 지체 없이 통지하여 정정하도록 조치하겠습니다.
4. 회사는 이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 당해 개인정보를 이용 및 제공을 하지 않습니다.
5. 이용자가 개인정보의 삭제를 요구하는 즉시 회사가 보유하고 있던 이용자의 모든 데이터는 영구히 재생할 수 없는 형태로 완전 파기 됩니다.
6. 탈퇴 절차를 마친 후 정상적으로 로그인 할 수 없으면 모든 정보가 파기된 것입니다.
7. 회사는 이용자의 개인정보를 안전하게 처리하며, 유출의 방지를 위하여 다음과 같은 방법을 통하여 개인 정보를 파기합니다.
  1) 종이에 출력된 개인정보 : 분쇄기로 분쇄
  2) 전자적 파일 형태로 저장된 개인정보 : 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제
​
제 7 조 (개인정보 보호를 위한 기술 및 관리 대책)

본 서비스는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 위조, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적, 관리적 대책을 강구하고 있습니다.
1. 기술적 대책
  1) 이용자의 개인정보는 비밀번호에 의해 보호되며 회원의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 기술적 대책을 적용합니다.
  2) 회원의 개인정보는 오직 본인만이 알 수 있는 비밀번호에 의해 보호되고 있고 개인정보 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다. 또한 고객센터를 통한 개인정보 변경의 경우에는 본인임을 확인하는 방법을 거칩니다.
  3) 회사는 해킹 등 외부침입에 대비하여 귀하의 개인정보가 유출되는 것을 막기 위해 현재 외부로부터 침입을 차단하는 장치를 이용하여 외부로부터의 공격, 해킹 등을 막습니다.
2. 관리적 대책
  1) 회사는 이용자의 개인정보에 대한 접근 권한을 최소한의 인원으로 제한하고 있습니다. 그 최소한의 인원에 해당하는 자는 다음과 같습니다.
  ① 개인정보 관리 책임자 및 담당자 등 개인정보 관리 업무를 수행하는 자
  ② 기타 업무상 개인정보의 처리가 불가피한 자
  ③ 이용자를 직접 상대로 하여 마케팅 업무를 수행하는 자
  2) 개인정보를 처리하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보 보호 의무 등에 관해 정기적인 교육 및 주지를 하고 있습니다.
  3) 입사시 전 직원의 보안 서약서를 통하여 사람에 의한 정보 유출을 사전에 방지하고 개인정보 처리 방침에 대한 이행 사항 및 직원의 준수 여부를 감사하기 위한 내부 절차를 마련하고 있습니다.
  4) 개인정보 관련 처리자의 업무 인수 인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확화하고 있습니다.
  5) 회사는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다.
  6) 이용자 개개인이 본인의 개인정보를 보호하기 위해서 자신의 아이디와 비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.
​
제 8 조 (개인정보의 제 3자 제공 및 공유)

1. 회사는 회원의 개인정보를 본 개인정보처리방침에서 명시된 범위를 초과하여 이용하거나 제 3자(타인 또는 타기업 기관)에 제공하지 않습니다. 다만, 회원의 동의가 있거나 다음 각호의 어느 하나에 해당하는 경우에는 예외로 합니다.
  1) 회원이 사전에 동의한 경우
  2) 서비스 제공에 따른 요금 정산을 위해 필요한 경우
  3) 관계법령에 의하여 수사, 재판 또는 행정상의 목적으로 관계기관으로부터의 요구가 있을 경우
2. 영업의 전부 또는 일부를 양도하거나, 합병/상속 등으로 서비스제공자의 권리, 의무를 승계하는 경우 개인정보보호 관련 회원의 권리를 보장하기 위하여 반드시 그 사실을 회원에게 통지합니다.
3. 보다 나은 서비스 제공을 위하여 회사가 개인정보를 타기업 등 제 3자에게 제공하는 것이 필요한 경우에는 회사는 사전에 공유되는 개인정보의 이용목적, 공유기간, 공유정보, 보호관리방법 등을 고지하여 동의를 구하는 절차를 거칩니다. 단, 시간상 사전 공지가 불가능한 예외적인 경우는 제공 및 공유가 공지와 동시에 이루어 질 수 있습니다. 회사는 개인정보처리방침에서 정한 본래의 수집목적 및 이용목적에 반하여 무분별하게 개인정보가 제공되지 않도록 최대한 노력합니다.
​
제 9 조 (개인정보의 위탁 관리)

1. 회사는 서비스 향상을 위하여 개인정보를 제한된 범위에서 타 업체에 위탁하여 관리하도록 할 수 있습니다.
2. 회사가 개인정보 처리을 위탁하는 업체는 아래와 같으며 관계 법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 하고 있습니다.
​
서비스 내용
안심번호 서비스 : 델피콤
​
개인정보의 보유 및 이용기간
위탁계약 종료시까지
​
제 10 조 (개인정보 관리책임자)

회사는 이용자가 좋은 정보를 안전하게 이용할 수 있도록 최선을 다하고 있습니다.
​
개인정보 보호 책임자
이름 : 김규한
이메일 : moebius.kim@gmail.com
전화 : 010-3930-8611
​
제 11조 (광고성 정보전송)

회사는 이용자의 명시적인 수신 거부 의사에 반하여 영리 목적의 광고성 정보를 전송하지 않습니다.
1. 회사는 이용자가 뉴스레터 등 전자 우편 전송에 대한 동의를 한 경우 전자 우편의 제목란 및 본문란에 다음 사항과 같이 이용자가 쉽게 알아 볼 수 있도록 조치합니다.
  1) 전자 우편의 제목란 : 전자 우편 본문란의 주요 내용을 표시합니다.
  2) 전자 우편의 본문란
  ① 이용자가 수신 거부의 의사표시를 할 수 있는 전송 자의 명칭, 전자우편주소, 전화번호 및 주소를 명시합니다.
  ② 이용자가 수신 거부의 의사를 쉽게 표시할 수 있는 방법을 한글 또는 영문으로 각각 명시 합니다.
  ③ 이용자가 동의를 한 시기 및 내용을 명시합니다.
2. 팩스, 휴대폰 문자 전송 등 전자 우편 이외의 문자 전송을 통해 영리 목적의 광고성 정보를 전송하는 경우에는 전송 내용 처음에 "(광고)"라는 문구를 표기하고 전송 내용 중에 전송 자의 연락처를 명시하도록 조치합니다.
​
제 12조 (의견 수렴 및 불만 처리)

1. 회사는 이용자의 의견을 소중하게 생각하며, 이용자는 의문 사항으로부터 언제나 성실한 답변을 받을 권리가 있습니다.
2. 회사는 이용자와의 원활환 의사 소통을 위해 고객센터를 운영하고 있습니다. 고객센터의 연락처는 다음과 같습니다.
[위솝 고객센터]
- 이용 시간 : 09:30 ~ 18:00(평일)
- 전자 우편 : wesop.co@gmail.com
- 전화번호 : 070-8633-1157
- 주 소 : 서울특별시 중구 퇴계로 18 ,5층 (회현동, 대우재단빌딩)
3. 전자 우편이나 팩스 및 우편을 이용한 상담은 접수 후 24시간 내에 성실하게 답변 드리겠습니다. 다만, 근무 시간 이후 또는 주말 및 공휴일에는 익일 처리하는 것을 원칙으로 합니다.
4. 기타 개인정보에 관한 신고나 상담이 필요한 경우에는 아래 기관에 문의하시기 바랍니다.
  1) 개인정보침해신고센터 (http://www.1336.or.kr / ☎118(ARS 내선 2번)
  2) 정보보호 마크인증위원회 (http://www.eprivacy.or.kr / ☎02-550-9531~2)
  3) 대검찰청 첨단범죄수사과 (http://www.spo.go.kr / ☎02-3480-2000)
  4) 경찰청 사이버 범죄 수사 센터 (http://cyber112.police.go.kr / ☎1566-0112)
  5) 방송통신위원회 (http://www.kcc.go.kr / ☎1335)
​
제 13 조 (이용자 및 법정대리인의 권리와 권리 행사방법 및 의무)

1. 이용자의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자가 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의 도용 등 허위 정보를 입력할 경우 회원 자격이 상실될 수 있습니다.
2. 이용자는 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 비밀번호를 포함한 이용자의 개인정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는『정보통신망 이용촉진 및 정보보호 등에 관한 법률』등에 의해 처벌받을 수 있습니다.
3. 이용자는 언제든지 『정보수정』 메뉴를 통해 자신의 개인정보를 조회하거나 수정할 수 있습니다.
4. 이용자는 언제든지 『회원탈퇴』 등을 통해 개인정보의 수집 및 이용 동의를 철회할 수 있습니다.
5. 만 14세 미만 아동의 경우, 법정대리인이 아동의 개인정보를 조회하거나 수정할 권리, 수집 및 이용 동의를 철회할 권리를 가집니다.
  1) 이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
​
제 14조 (고지의 의무)

현 개인정보 처리 방침은 2018년 1월 1일에 제정되었으며 정부의 정책 또는 보안 기술의 변경에 따라 내용의 추가. 삭제 및 수정이 있을 시에는 변경되는 개인정보 처리 방침을 시행하기 최소 7일 전부터 사이트를 통해 고지할 것입니다.
개인정보 처리 방침 버전 번호 : ver.1.0
개인정보 처리 방침 변경 공고 일자 : 2018년 01월 01일
개인정보 처리 방침 시행 일자 : 2018년 01월 01일
`;
  return text;
};

const TermPolicy = () => {
  const text = `이용약관
​
제 1 조 (목적)

본 약관은 위솝(이하 '회사'라 함)이 제공하는 샵솔(SHOPSOL)에 관한 모든 제품 및 서비스(이하 '서비스'라 함)의 이용에 관한 조건에 대해 서비스를 이용하는 회원(이하 '회원'이라 함)과 회사간의 권리, 의무 및 책임사항, 기타 필요사항을 규정함을 목적으로 합니다.
​
제 2조 (용어의 정의)

본 약관에서는 다음 용어를 사용합니다.
1. '서비스'라 함은 회사가 운영하는 사이트를 통해 회원이 등록하는 자료 등을 각각의 목적에 맞게 분류가공, 집계하여, 정보를 제공하는 것을 말합니다.
2. '사이트'라 함은 회사가 운영하는 웹사이트, 모바일앱 등의 매체를 통해 서비스를 제공하는 장소를 통칭합니다.
3. '회원'이라 함은 회사가 제공하는 서비스를 이용하거나 이용하려는 자로, 회사가 요청하는 절차를 거쳐 서비스 이용 계약을 체결하여 회사로부터 회원으로 인정받은 자를 말합니다.
4. '아이디'라 함은 회원식별과 회원의 서비스 이용을 위하여 회원이 직접 지정하고 이를 회사가 부여하는 문자 또는 숫자의 조합을 말합니다.
5. '비밀번호'라 함은 서비스를 이용하려는 사람이 아이디를 부여받은 자와 동일인임을 확인하고, 회원권익의 보호를 위해 회원이 직접 지정한 문자 또는 숫자의 조합을 말합니다.
6. '콘텐츠'란 텍스트, 이미지, 동영상, 음성, 소프트웨어, 프로그램, 코드 기타 자료를 말합니다.
7. '사용자 콘텐츠'란 고객 또는 사용자가 본 서비스를 이용하여 생선한 콘텐츠를 말합니다.
8. '유료서비스'라 함은 회사가 유료로 제공하는 서비스를 의미합니다.
9. '제휴사'란 회사와 별도의 계약을 맺고 회사와 함께, 또는 회사로부터 위탁을 받아 서비스를 제공하는 개인, 단체 또는 회사를 말합니다.
10. '포인트'라 함은 서비스의 효율적 이용을 위해 회사가 임의로 책정 또는 지급, 조정할 수 있는 재산적 가치가 없는 서비스 상의 가상 데이터를 의미합니다.
​
제 3 조 (약관의 효력 및 개정)

1. 회사는 이 약관의 내용과 상호, 사업장 소재지, 대표자의 성명, 사업자등록번호, 연락처 등을 회원이 알 수 있도록 사이트에 게시하거나 기타의 방법으로 회원에게 공지하여야 합니다.
2. 이 약관의내용은 사이트에 게시하거나 기타의 방법으로 회원에게 공지하고, 이에 동의한 회원이 서비스에 가입함으로서 효력이 발생합니다. 회사는 회원이 동의하기에 앞서 약관의 내용을 회원이 쉽게 이해하여 착오없이 본 서비스를 이용할 수 있도록 별도의 공간을 제공하여 회원의 확인을 구합니다.
3. 회사는 약관의 규제에 관한 법률, 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호, 전자상거래등에서의 소비자보호에관한법률 등에 관한 법률 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
4. 회사는 약관을 개정할 경우에는 개정 사유와 적용일자를 명시하고 현행 약관과 함께 본 조 2항의 방식에 따라 개정 약관의 적용 일 최소 7일 이전부터 적용 일 전일까지 공지 또는 통지함으로서 효력이 발생됩니다. 다만, 회원에게 불리한 조건으로 개정될 경우 최소한 15일 이상의 사전 유예기간을 두고 공지 또는 통지하여야 합니다.
5. 회원이 약관 공지의 날로부터 지정된 유예기간 내에 개정될 약관에 대해 별도의 의사표시를 하지 않을 경우 회사는 회원이 개정된 약관의 내용에 대해 동의한 것으로 간주합니다.
6. 회원은 회사가 개정한 약관의 내용에 동의하지 않을 경우 회원 탈퇴(가입해지)를 요청할 수 있습니다.
​
제 4 조 (약관 외 준칙)

본 약관에서 규정하지 않은 사항에 관해서는 전자상거래등에서의 소비자보호에 관한 법률, 콘텐츠산업진흥법, 전기통신기본법, 저작권법, 신용정보이용 및 보호에관한 법률, 기타 관련 법령의 규정에 따릅니다.
​
제 5 조 (이용 계약의 성립)

1. 회사가 제공하는 서비스를 받고자 하는 자가 내용을 동의하고 이용 신청하여 회사가 승낙함으로서 성립합니다.
2. 가입신청자가 이용계약시 사이트 상의 본 약관을 확인한 후 '동의함' 버튼을 누르면 회사의 본 약관에 대하여 동의한 것으로 간주합니다.
​
제 6 조 (이용 신청의 승낙과 제한)

1. 회사는 가입신청자의 가입승낙처리 시 신청순서에 따라 서비스 이용에 승낙하는 것을 원칙으로 합니다.
2. 회사는 다음 각 호에 해당하는 이용계약 신청에 대하여 이를 승낙하지 않을 수 있습니다.
  1) 타인의 명의를 도용하거나 실명이 아닌 경우
  2) 입력 내용 중 허위정보를 기재하여 신청한 경우
  3) 현행법규에 위배되는 내용이나 사회의 미풍양속을 저해할 목적으로 신청한 경우
  4) 회사의 권한으로 회원자격을 상실한 적이 있는 회원이 재가입한 경우
  5) 기타 회사가 서비스 운영상 필요하다고 인정되는 경우
3. 회사는 아래 사항에 대하여 그 신청에 승낙을 유보할 수 있습니다.
  1) 설비의 여유가 없는 경우
  2) 기술상 지장이 있는 경우
  3) 기타 회사의 사정상 필요한 경우
4. 회사는 가입승낙 완료 후 본 조 제 2항의 각 호에 따른 사유 발견시 이용 승낙을 철회할 수 있습니다.
​
제 7 조 (서비스의 내용)

1. 회사는 사이트를 통하여 제 2조 1항의 서비스를 제공할 수 있으며, 그 내용은 다음 각 호와 같습니다.
  1) 기본제공 무료서비스
  2) 유료제공 부가서비스
2. 본 회사의 서비스는 사이트에 기재되어 있으며, 서비스의 추가 및 수정 사항이 있을시에 변동된 내용을 기재합니다.
​
제 8 조 (회원정보 노출 및 삭제)

1. '개인회원'의 사이트을 이용한 정보의 작성은 개인회원이 작성 및 수정시 희망하는 형태로 노출하는 것을 원칙으로 합니다.
  1) 회사는 개인회원이 회원정보, 면접정보의 공개/비공개 지정을 자유롭게 선택할 수 있도록 합니다.
  2) 회사는 안정적인 서비스 제공을 위헤 테스트 및 모니터링 용도에 한하여 개인회원의 회원정보를 열람할 수 있습니다.
2. '기업회원'은 구직을 희망하는 '개인회원'이 채용공고를 열람할 시 해당 기업에 대한 판단을 돕기 위해 기업 정보를 정확히 기재하여야 합니다. 또한 '기업회원'에 의해 등록되는 채용공고 정보 중 각 호에 해당하는 경우 회사는 사전통지 없이 이를 삭제할 수 있습니다.
  1) 구인을 가장하여 물품판매, 부업알선, 자금모집, 수강생 모집 등을 행하는 경우
  2) 회사명 및 연락처 등 중요 기업 사항이 명확하게 기입되지 않은 경우
  3) 등록된 공고에 기재된 직종, 고용형태, 근로조건 등이 실제와 현저히 다른 경우
  4) 공공질서 및 미풍양속에 위반하는 내용인 경우
  5) 현행법에 위배되는 내용이나 범죄적 행위에 결부된다고 인정되는 내용인 경우
  6) 제 3자를 비방하거나 명예를 손상시키는 경우
  7) 저작권 등 기타 권리를 침해하는 내용인 경우
  8) 기타 관계법규에 위배되는 내용이나, 서비스의 원활한 운영을 위하여 운영자가 필요하다고 판단한 경우
​
제 9 조 (제휴를 통한 서비스)

1. 회사는 제휴 관계를 체결한 여타 제휴사에게 회원이 동의한 경우 정보를 제공할 수 있습니다.
2. 회사는 제휴사 정보를 가급적 본 약관에 명시하여야 하며, 본 약관에서 명시하지 못한 제휴사 정보는 사이트를 통하여 회원에게 안내하여야 합니다.
​
제 10 조 (서비스 요금 및 결제)

1. 회원 가입은 무료입니다.
2. 부가서비스는 유료로 제공될 수 있습니다.
3. 회사는 유료서비스의 요금 및 상세 내용을 사이트에 공시 하여야 합니다.
4. 무료제공 기본서비스의 유료 전환시에는 회사가 회원이 등록한 이메일 또는 SMS 등의 기타 방법으로 회원에게 사전 통지합니다.
5. 회원은 유료서비스 이용요금의 지급방법으로 회사가 정한 결제 방법중 하나를 선택할 수 있으며, 결제의 이행을 위하여 필요한 개인정보를 정확하게 입력하여야 합니다.
6. 회사는 회원이 결제에 필요한 개인정보를 허위 또는 부정확하게 입력함으로서 인하여 발생한 회원의 손해에 대해 책임을 부담하지 않습니다. 단, 회사의 고의 또는 중과실에 의한 경우에는 통상손해에 한하여 책임범위를 한정합니다.
​
제 11 조 (포인트 및 충전금)

1. 포인트는 유료상품 구매 시의 사은, 이벤트, 고객보상 등의 사유로 적립되며, 현금으로 출금되지 않습니다.
2. 위의 적립사유가 해제되었을 경우, 적립된 포인트를 회원보유분에서 회수하며, 결제 시 사용한 서비스를 취소할 경우 사용분에 해당하는 포인트를 제외하고 사용이전의 상태로 재적립 받을 수 있습니다.
3. 서비스 운영 정책에 따라 포인트의 상품별 적립기준, 적립비율, 사용조건 및 제한 등은 달라질 수 있으며, 이에 대한 사항은 사이트에 게시하거나 통지한다. 포인트 적립시 별도의 유효기간을 정한 바가 없으면 적립일로부터 12개월의 유효기간을 보장합니다.
4. 포인트의 적립시점과 무관하게 유효기간이 지나거나, 회원이 12개월 동안 서비스를 이용하지 않을 경우, 회원이 가입해지한 경우, 제 18조 2항에 의해 가입해지하거나 서비스가 중지된 경우 포인트는 전액 자동소멸되어 복구되지 않습니다.
5. 충전금은 회사가 정한 결제 수단을 이용하여 미리 입금해 둔 금액으로, 회사의 유료서비스 결제 시 사용할 수 있습니다.
6. 서비스 운영정책에 따라 충전금을 구매할 수 있는 수단, 충전금으로 결제할 수 있는 유료서비스 등은 달라질 수 있으며, 이에 대한 사항은 서비스 화면에 게시하거나 공지합니다.
7. 회원은 회사에 잔여 충전금의 환불을 요구할 수 있으며, 회사는 회원에게 환불에 필요한 서류를 요청할 수 있다. 환불 시 환불수수료를 공제하고 환불할 수 있으며, 충전금 충전방법에 따라 금융비용이 환불수수료를 초과하여 발생할 경우 이는 회원의 부담으로 합니다.
8. 잔여 충전금 중 충전일로부터 5년이 초과할 때까지 이용하지 않은 금액은 회원이 등록한 이메일 또는 SMS 등의 기타 방법으로 사전 통지 후 7일 이내에 소멸시효에 따라 소멸됩니다.
9. 회원탈퇴 시 보유중인 충전금은 환불을 요청할 수 있으며, 이와같은 절차없이 회원탈퇴한 경우 충전금은 전액 자동소멸되며 복구되지 않습니다.
​
제 12 조 (서비스 이용시간 및 제한)

1. 회사는 회원의 시용신청을 승낙한 때부터 서비스를 개시합니다. 단, 회사의 재량으로 일부 서비스의 경우에는 지정된 일자부터 서비스를 개시하며, 유료서비스의 경우 회사가 지정하는 수단으로 결제가 완료된 후 이용 가능합니다.
2. 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만 정기점검 등 회사의 업무상 또는 기술상의 이유로 서비스가 일시 중지될 수 있으며, 자료가공과 개신을 위한 시스템 작업시간, 장애해결을 위한 보수작업 시간 등 운영상의 목적으로 회사가 정한 기간에는 서비스가 일시 중지될 수 있습니다. 이러한 경우 회사는 원칙적으로 서비스의 일시 주이 및 중지 기간을 사전에 공지하되, 이를 미리 공지할 수 없는 급박하거나 불가피한 사정이 있을 때에는 차후 공지합니다.
3. 회사는 다음 각 호에 해당하는 경우 서비스의 제공을 중지할 수 있습니다.
  1) 설비의 보수 등 회사의 필요에 의해 사전에 회원들에게 통지한 경우
  2) 기간통신사업자가 전기통신서비스 제공을 중지하는 경우
  3) 기타 불가항력적인 사유에 의해 서비스 제공이 객관적으로 불가능한 경우
4. 전 항의 경우 회사는 기간의 정함이 있는 유료서비스 회원들에게는 그 이용기간을 연장하는 등의 방법으로 손실을 보상합니다.
​
제 13 조 (정보의 제공 및 광고의 게재)

1. 회사는 회원에게 서비스 이요에 필요가 있다고 인정되거나 서비스 개선 및 회원대상의 서비스 소개 등의 목적으로 하는 각종 정보에 대해서 사이트, 이메일, SMS 등을 이용한 방법으로 제공할 수 있습니다.
2. 회사는 제공하는 서비스와 관련되는 정보 또는 광고를 사이트, 이메일, SMS 등에 게재할 수 있으며, 광고가 게재된 이메일을 수신한 회원은 수신거절을 회사에게 할 수 있습니다.
3. 회사는 서비스상에 게재되어 있거나 서비스를 통한 광고주의 판촉활동에 회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 모든 손실과 손해에 대해 책임을 지지 않습니다.
4. 서비스의 회원은 서비스 이용 시 노출되는 광고게재에 대해 동의 하는 것으로 간주합니다.
​
제 14 조 (자료내용의 책임과 회사의 정보 수정 권한)

1. 자료내용은 회원이 등록한 개인정보 및 사이트에 게시한 게시물을 말합니다.
2. 회원은 자료내용 및 게시물을 사실에 근거하여 성실하게 작성해야 하며, 만일 자료의 내용이 사실이 아니거나 부정확하게 작성되어 발생하는 모든 책임은 회원에게 있습니다.
3. 회사는 회원이 등록한 자료 내용에 오자, 탈자 또는 사회적 통념에 어긋나는 문구와 내용이 있을 경우 이를 수정할 수 있습니다.
4. 회원이 등록한 자료로 인해 타인으로부터 허위사실 및 명예훼손 등으로 삭제 요청이 접수된 경우 회사는 회원에게 사전 통지 없이 본 자료를 삭제할 수 있으며 삭제 후 이메일 등의 방법으로 통지할 수 있습니다.
​
제 15 조 (자료의 저작권 및 활용)

1. 회사가 작성한 게시물 또는 저작물에 대한 저작권 및 기타 재적재산권은 회사에 귀속합니다.
2. 회원이 사이트 내에 게시한 게시물의 저작권은 회원에게 귀속됩니다. 단, 회원은 자신이 창작, 등록한 게시물에 대하여 무상의 비독점적 사용권을 회사에 부여하고, 이 사용권은 회사가 서비스를 운영하는 동안 계속 유효하며, 회원탈퇴한 후에도 이와 같습니다.
3. 게시물에 대한 권리와 책임은 게시물을 게시한 회원에게 있으며, 회사는 회원의 동의 없이 이를 회사가 운영하는 서비스 이외의 영리적 목적으로 사용할 수 없습니다. 단, 비영리적인 경우에는 해당하지 않습니다.
​
제 16조 (회사의 의무)

1. 회사는 본 약관에서 정한 바에 따라 지속적, 안정적으로 서비스를 제공할 수 있도록 최선의 노력을 다합니다.
2. 회사는 특별한 사정이 없는 한 회원이 서비스를 이용 신청한 후 최대한 빨리 서비스를 이용할 수 있도록 합니다.
3. 회사는 서비스에 관련된 설비를 항상 운용할 수 있는 상태로 유지 보수하고, 장애가 발생하는 경우 지체없이 이를 수리 및 복구할 수 있도록 최선의 노력을 다해야 합니다. 다만, 천재지변 등 예측하지 못한 일이 발생하거나 시스템의 장애가 발생하여 서비스가 중단될 경우 이에 대한 손해는 회사가 책임을 지지 않으며, 이 경우 자료의 복구나 정상적인 서비스 지원이 되도록 최선을 다할 의무가 있습니다.
4. 회사는 서비스와 관련된 회원의 의견이나 불만사항이 접수되는 경우, 그 내용이 타당하다고 판단될 경우 상황에 맞는 적절한 조치를 취하여야 합니다.
5. 회원이 등록한 자료로 인하여 서비스의 원활한 운영에 영향을 미친다고 판단될 시, 등록된 모든 자료를 회원의 사전동의 없이 삭제할 수 있습니다.
6. 회사는 유료 결제와 관련 결제 정보를 5년 이상 보존합니다. 다만, 회원자격이 없는 회원은 예외로 합니다.
​
제 17조 (회원의 의무)

1. 회원은 관계법령과 본 약관의 규정 및 기타 회사가 공지, 통지하는 사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위, 회사의 명예를 손상시키는 행위, 타인에게 피해를 주는 행위를 해서는 안됩니다.
2. 회원은 서비스를 이용함으로써 얻은 정보를 채용 이외의 목적으로 회사의 사전 승낙 없이 복제, 송신, 출판, 전송, 배포, 방송, 기타 방법에 의하여 영리목적으로 이용하거나 제 3자에게 이용하게 하여서는 안됩니다.
3. 회원이 신청한 유료서비스는 신청 또는 등록과 동시에 회사와 채권, 채무 관계가 발생하며, 회원은 이에 대한 요금을 지정한 기일 내에 납부하여야 합니다.
4. 회원에게 부여된 아이디와 비밀번호의 관리 소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있으며 회원은 자신의 아이디나 비밀번호가 부정하게 사용되었다는 사실을 발견한 경우 즉시 회사에 알려야 하며, 이를 알리지 않아 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.
5. 회원은 회사가 공지사항에 게시하거나 별도로 공지한 이용제한 사항을 준수하여야 합니다.
​
제 18 조 (계약해지/서비스중지/자료삭제)

1. 회원은 언제든지 회사의 고객센터 또는 회원 탈퇴 메뉴 등을 통하여 회사에 서비스 이용해지 의사를 표함으로써 회사와의 이용계약을 종료할 수 있으며, 회사는 관련법 등이 정하는 바에 따라 이를 처리하여야 합니다.
2. 다음의 각 사항에 해당하는 경우 회사는 회원의 사전 동의 없이 계약 해지하거나 서비스 중지, 자료 삭제 조치를 취할 수 있습니다.
  1) 기입사항이 명확하게 기입되지 않거나 사실과 다르게 조작되어 허위로 등록된 경우
  2) 회원가입 또는 자료 등록 시 본 서비스에서 안내하는 방법으로 가입하지 않았거나 등록하지 않았을 때
  3) 현행법에 위배되는 내용이나 범죄적 행위에 결부된다고 인정되거나, 우려가 되는 경우
  4) 회사의 저작권 또는 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우
  5) 규정한 유료서비스 이용 요금을 납부하지 않았을 때
  6) 기타 관계법규에 위배되는 내용이나, 서비스의 원활한 운영을 위하여 운영자가 필요하다고 판단한 경우
​
제 19 조 (손해배상 및 면책)

1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우 서비스 제공에 관한 책임이 면제됩니다.
2. 회사는 서비스 이용과 관련하여 회원에게 발생한 손해 가운데 회원의 고의, 과실 및 회원의 귀책사유로 인한 서비스 이용의 장애 및 손해에 대하여 책임을 지지 않습니다.
3. 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.
4. 회원이 본 약관의 규정에 위반한 행위로 회사 및 제3자에게 손해를 입히거나 회원의 책임이 있는 사유에 의해 회사 및 제3자에게 손해를 입힌 경우에는 회원은 그 손해를 배상하여야 할 책임이 있습니다.
5. 회사는 본 약관의 제 16조 등의 규정에 위반한 행위로 회원에게 손해가 발생한 경우 회사는 그 손해에 대해 통상손해에 한하여 책임범위를 한정합니다.
​
제 20 조 (개인정보보호)

회사는 회원의 개인정보보호를 위하여 노력해야하며 회원의 개인정보보호에 관해서는 정보통신망이용촉진 및 정보보호 등에 관한 법률에 따르고, 서비스를 위한 앱 및 웹에 개인정보처리방침을 고지합니다.
​
제 21 조 (신용 정보의 제공 활용 동의)

1. 회사가 회원가입과 관련하여 취득한 회원의 신용정보를 타인에게 제공하거나 활용하고자 할 때에는 '신용정보이용및보호에관한법률' 제23조의 규정에 따라 사전에 그 사유 및 해당기관 또는 업체명 등을 밝히고 해당 회원의 동의를 얻어야 합니다.
2. 본 서비스와 관련하여 회사가 회원으로부터 '신용정보이용및보호에관한법률'에 따라 타인에게 정보제공 활용에 동의를 얻은 경우 회원은 회사가 신용정보 사업자 또는 신용정보 집중기관에 정보를 제공하여 회원의 신용을 판단하기 위한 자료로 활용하거나, 공공기관에서 정책자료로 활용되도록 정보를 제공하는 데 동의한 것으로 간주합니다.
3. 회사는 필요한 경우 원활한 서비스 이용과 허위정보로 인한 피해를 방지하기 위해 신용평가회사를 통해 실명인증을 시행할 수 있습니다.
​
제 22 조 (개인정보의 위탁)

회사는 수집된 개인정보의 처리 및 관리 등의 업무를 스스로 수행함을 원칙으로 합니다만, 필요한 경우 업무의 일부 또는 전부를 회사가 선정한 제3자에 위탁할 수 있습니다. 이는 개인정보의 위탁을 통해 회원의 각종 세금 및 보험에 대한 신고(전자신고 포함) 및 대행 업무를 보다 원활하게 하거나 혹은 더 나은 서비스의 제공을 위해 회사가 직접 해당 각종 세금 및 보험에 대한 신고 및 대행 업무를 직접 수행할 수 있습니다.
​
제 23 조 (분쟁의 해결)

1. 회사와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 노력을 하는 것을 원칙으로 합니다.
2. 전항의 노력에도 불구하고, 분쟁에 대해 소송이 제기될 경우 회사의 본사 소재지를 관할하는 법원을 전속 관할법원으로 합니다.
​
(시행일) 본 약관은 2018년 1월 1일부터 시행합니다.
`;

  return text;
};
const LocationPolicy = () => {
  const text = `위치기반서비스
​
제 1 조 (목적)

본 약관은 위솝(이하 '회사')이 제공하는 위치기반 서비스(이하 '서비스')를 이용함에 있어 회사와 회원의 권리·의무 및 책임사항을 규정함을 목적으로 한다.
​
제 2 조 (이용약관의 효력 및 변경)

1. 본 약관은 서비스를 신청한 고객 또는 개인위치정보주체가 본 약관에 동의하고 회사가 정한 소정의 절차에 따라 서비스의 이용자로 등록함으로써 효력이 발생한다.
2. 회원이  모바일앱에서 본 약관의 "동의하기" 버튼을 클릭하였을 경우 본 약관의 내용을 모두 읽고 이를 충분히 이해하였으며, 그 적용에 동의한 것으로 본다.
3. 회사는 위치정보의 보호 및 이용 등에 관한 법률, 콘텐츠산업 진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 소비자기본법, 약관의 규제에 관한 법률 등 관련법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있다.
4. 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지한다. 단 회원의 권리, 의무에 중대한 영향을 주는 변경의 경우에는 적용일자 30일 전부터 공지하도록 한다.
5. 회원은 변경된 약관에 대해 거부할 권리가 있다. 회원은 변경된 약관이 공지된 지 15일 이내에 거부의사를 표명할 수 있다. 회원이 거부하는 경우 본 서비스 제공자인 회사는 15일의 기간을 정하여 회원에게 사전 통지 후 당해 회원과의 계약을 해지할 수 있다. 만약, 회원이 거부의사를 표시하지 않거나 시행일 이후에 서비스를 이용하는 경우에는 동의한 것으로 간주한다.
​
제 3 조 (약관 외 준칙)

본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에 명시되지 아니한 사항에 대하여는 관계법령 또는 상관례에 따르며, 위치정보의 보호 및 이용 등에 관한 법률(이하 '위치정보법') 전기통신사업법, 정보통신망 이용촉진 및 보호 등에 관한 법률, (이하 '정보통신망법'), 개인정보보호법 등 관계법령 및 회사가 별도로 정한 이용지침 등의 규정에 따른다.
​
제 4 조 (서비스의 내용)

회사가 제공하는 서비스는 아래와 같다.

<서비스 명>
스마트 출퇴근 / 번개면접 서비스
<스마트출퇴근 서비스 내용>
- 현 위치를 이용한 출퇴근 여부 확인
​
<번개면접 서비스 내용>
- 현 위치를 이용한 국내지역 채용정보 검색
- 저장한 위치를 기반으로 채용정보 검색결과 제시
​
제 5 조 (서비스 요금)

1. 회사가 제공하는 서비스의 가입은 무료이며, 별도의 유료 서비스의 경우 해당 서비스에 명시된 요금을 지불하여야 사용 가능하다.
2. 회사는 유료 서비스 이용요금을 회사와 계약한 전자지불업체에서 정한 방법에 의하거나 회사가 정한 청구서에 합산하여 청구할 수 있다.
3. 유료서비스 이용을 통하여 결제된 대금에 대한 취소 및 환불은 회사의 결제 이용약관 등 관계법에 따른다.
4. 회원의 개인정보도용 및 결제사기로 인한 환불요청 또는 결제자의 개인정보 요구는 법률이 정한 경우 외에는 거절될 수 있다.
5. 무선 서비스 이용 시 발생하는 데이터 통신료는 별도이며 가입한 각 이동통신사의 정책에 따른다.
6. MMS 등으로 게시물을 등록할 경우 발생하는 요금은 이동통신사의 정책에 따른다.
​
제 6 조 (서비스변경 및 중지)

1. 회사가 서비스 내용을 변경하거나 종료하는 경우 회사는 회원의 등록된 전자우편 주소로 이메일을 통하여 서비스 내용의 변경 사항 또는 종료를 통지하여야 한다.
2. 1항의 경우 불특정 다수인을 대상으로 통지를 함에 있어서는 사이트 등 기타 회사의 공지사항을 통하여 회원들에게 통지할 수 있다.
​
제 7 조 (서비스이용의 제한 및 중지)

1. 회사는 아래 각 호에 해당하는 사유가 발생한 경우에는 회원의 서비스 이용을 제한하거나 중지시킬 수 있다.
  1) 회원이 회사 서비스의 운영을 고의 또는 중과실로 방해하는 경우
  2) 서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우
  3) 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우
  4) 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때
  5) 기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우
2. 회사는 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제한기간 등을 회원에게 알려야 한다.
​
제 8 조 (개인위치정보의 이용 또는 제공)

1. 회사는 서비스 제공을 위하여 회원의 위치정보를 이용할 수 있으며, 회원은 본 약관에 동의함으로써 이에 동의한 것으로 본다.
2. 회사는 타사업자 또는 이용 고객과의 요금정산 및 민원처리를 위해 위치정보 이용·제공사실 확인자료를 자동 기록·보존하며, 회원탈퇴시 파기한다.
3. 회사는 개인위치정보를 회원이 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신 단말장치로 매회 회원에게 제공받는 자, 제공일시 및 제공목적을 즉시 통보한다. 단, 아래 각 호에 해당하는 경우에는 회원이 미리 특정하여 지정한 통신 단말장치 또는 전자우편주소로 통보한다.
  1) 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의 수신기능을 갖추지 아니한 경우
  2) 회원이 온라인 게시 등의 방법으로 통보할 것을 미리 요청한 경우
4. 회사는 회원의 개인위치정보 또는 위치정보 이용·제공사실 확인자료를 이용약관에 명시 또는 고지한 범위를 넘어 이용하거나 제3자에게 제공하고자 하는 경우에는 회원의 동의를 받아야 한다. 단, 아래의 경우는 제외한다.?
  1) 위치정보 및 위치기반서비스 제공에 따른 요금정산을 위하여 위치정보 이용·제공사실 확인자료가 필요한 경우?
  2) 통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우
​
제 9 조 (개인위치정보주체의 권리)

1. 회원은 회사에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한 동의의 전부 또는 일부를 철회할 수 있다. 이 경우 회사는 위치정보 이용·제공사실 확인자료를 파기하여야 한다.
2. 회원은 회사에 대하여 언제든지 개인위치정보의 이용 또는 제공의 일시적인 중지를 요구할 수 있으며, 회사는 이를 거절할 수 없고 이를 위한 기술적 수단을 갖추고 있어야 한다.
3. 회원은 회사에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수 있다. 이 경우 회사는 정당한 사유 없이 회원의 요구를 거절할 수 없다.
  1) 본인에 대한 위치정보 이용·제공사실 확인자료
  2) 본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법률 규정에 의하여 제3자에게 제공된 이유 및 내용
4. 회원은 제1호 내지 제3호의 권리행사를 위해 회사의 소정의 절차를 통해 요구할 수 있다.
​
​
제 10 조 (법정대리인의 권리)

1. 회사가 14세 미만의 회원으로부터 제 8조의 규정에 의하여 개인위치정보를 이용 또는 제공하고자 하는 경우에는 그 법정대리인의 동의를 받아야 한다.
2. 본 약관 제 9조(개인위치정보주체의 권리) 의 규정은 제1항의 규정에 의하여 법정대리인이 동의를 하는 경우에 이를 준용한다. 이 경우 "회원"은 "법정대리인"으로 본다.
​
제 11 조 (8세 이하의 아동 등의 보호의무자의 권리)

1. 회사는 아래의 경우에 해당하는 자(이하 "8세 이하의 아동"등이라 한다)의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 본다.
  1) 8세 이하의 아동
  2) 금치산자
  3) 장애인복지법제2조제2항제2호의 규정에 의한 정신적 장애를 가진 자로서 장애인고용촉진 및 직업재활법 제2조제2호의 규정에 의한 중증장애인에 해당하는 자(장애인복지법 제29조의 규정에 의하여 장애인등록을 한 자에 한한다)
2. 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 한다.
3. 본 약관 제 9조(개인위치정보주체의 권리) 의 규정은 제2항의 규정에 의하여 보호의무자가 동의를 하는 경우에 이를 준용한다. 이 경우 "회원"은 "보호의무자"로 본다.
​
제 12 조 (위치정보관리책임자의 지정)

1. 회사는 위치정보를 적절히 관리, 보호하고 개인위치정보주체의 불만을 원활히 처리할 수 있도록 실질적인 책임을 질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영한다.
2. 위치정보관리책임자는 위치기반서비스를 제공하는 부서의 부서장으로서 구체적인 사항은 본 약관의 부칙에 따른다.
​
제 13 조 (손해배상)

1. 회사가 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 제26조의 규정을 위반한 행위로 회원에게 손해가 발생한 경우 회원은 회사에 대하여 손해배상 청구를 할 수 있다. 이 경우 회사는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없다.
2. 회원이 본 약관의 규정을 위반하여 회사에 손해가 발생한 경우 회사는 회원에 대하여 손해배상을 청구할 수 있다. 이 경우 회원은 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없다.
​
제 14 조 (면책사항)

1. 회사는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 회원에게 발생한 손해에 대해서는 책임을 부담하지 않는다.
  1) 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우
  2) 서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우
  3) 회원의 귀책사유로 서비스 이용에 장애가 있는 경우
  4) 제1호 내지 제3호를 제외한 기타 회사의 고의·과실이 없는 사유로 인한 경우
2. 회사는 서비스 및 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며 이로 인해 발생한 회원의 손해에 대하여는 책임을 부담하지 아니한다.
​
제 15 조 (규정의 준용)

본 약관은 대한민국법령에 의하여 규정되고 이행되며, 규정되지 않은 사항에 대해서는 관련법령 및 상관습에 의한다.
​
제 16 조 (분쟁의 조정 및 기타)

1. 회사는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 위치정보의 보호 및 이용 등에 관한 법률 제28조의 규정에 의한 방송통신위원회에 재정을 신청할 수 있다.
2. 회사 또는 고객은 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 개인정보보호법 제43조의 규정에 의한 개인정보분쟁조정위원회에 조정을 신청할 수 있다.
​
제 17 조 (회사의 연락처)

회사의 상호 및 주소 등은 다음과 같다.
1. 상호 : 위솝
2. 주소 : 서울특별시 중구 퇴계로 18 ,5층 (회현동, 대우재단빌딩)
3. 대표전화 : 070-8633-1157
​
부칙
​
(시행일) 본 약관은 2018년 1월 1일부터 시행합니다.
`;
  return text;
};
