import React from 'react';
import './css/L04_Section.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import SectionImg from './img/section6.png';
import SectionImg1 from './img/section6_1.png';
import SectionImg2 from './img/section6_2.png';
import Div100vh from 'react-div-100vh';

function L04_Section03() {
  setTimeout(function () {
    $('.section3_img1').css('display', 'inline-block');
  }, 400);

  setTimeout(function () {
    $('.section3_img2').css('display', 'inline-block');
  }, 800);

  setTimeout(function () {
    $('.section3_img3').css('display', 'inline-block');
  }, 1200);

  return (
    <Div100vh>
      <div
        id="ContentWrap"
        className="col-12 d-flex flex-row justify-content-center align-items-center overflow-hidden"
      >
        <div
          id="cntWrap"
          className="d-flex justify-content-center align-items-center"
        >
          <div className="d-flex flex-row justify-content-center align-items-center">
            <div className="itemWrap">
              <img
                className="itemImg section6_img1 animate__animated animate__pulse"
                alt="기능소개이미지"
                src={SectionImg1}
              />
            </div>
            <img
              className="section_img4"
              alt="기능소개이미지"
              src={SectionImg}
            />
          </div>
          <div id="kakaoTextWrap">
            <p className="sectionMiniTitle">급여명세서교부 무료서비스</p>
            <h2 className="sectionTitle">
              카카오톡 발송으로
              <br />
              간편한 급여명세서 교부
            </h2>
            <p className="sectionText">
              간단한 입력과 터치 한번으로 급여명세서를
              <br />
              쉽고 간편하게 교부할 수 있습니다.
            </p>
          </div>
        </div>
      </div>
    </Div100vh>
  );
}

export default L04_Section03;
