import React, { useState, useEffect } from 'react';
import './css/L03_Intro.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import Tab1 from './L03_Intro_tab1';
import Tab2 from './L03_Intro_tab2';
import Tab3 from './L03_Intro_tab3';
import Tab4 from './L03_Intro_tab4';
import Tab5 from './L03_Intro_tab5';
import Tab6 from './L03_Intro_tab6';
import 'animate.css';
import Div100vh from 'react-div-100vh';
import New from './img/new.png';

const tabList = {
  0: <Tab1 />,
  1: <Tab2 />,
  2: <Tab3 />,
  3: <Tab4 />,
  4: <Tab5 />,
  5: <Tab6 />,
};

const introEx = {
  0: `직원의 출퇴근 시간 기록을 실시간 알림으로 받을 수 있고\n 과거부터 현재까지 모든 근무기록을 한눈에 확인할 수 있습니다.`,
  1: `저장된 출퇴근 시간 데이터를 기반으로\n고용주 실부담금과 근로자 실수령액이 자동으로 계산됩니다.`,
  2: `바코드 인식으로 쉽고 빠르게 등록이 가능하며,\n유통기한 현황을 통해 버려지는 식자제를 줄이고\n금전적 손실을 줄일 수 있습니다.`,
  3: `놓치기 쉬운 사업장 업무를\n꼼꼼하게 관리할 수 있도록 도와줍니다.`,
  4: `사업주가 작성한 지시사항에 대해\n직원들과 소통이 가능하고, 업무 실수 리스크가 감소합니다.`,
  5: `복잡하고 어려운 급여명세서를 쉽고 빠르게 작성하고\n터치 한 번으로 간편하게 교부할 수 있습니다.`,
};

const introEx2 = {
  0: `직원의 출퇴근 시간 기록을\n실시간 알림으로 받을 수 있고\n과거부터 현재까지 모든 근무기록을\n한눈에 확인할 수 있습니다.`,
  1: `저장된 출퇴근 시간 데이터를 기반으로\n고용주 실부담금과 근로자 실수령액이\n자동으로 계산됩니다.`,
  2: `바코드 인식으로 쉽고 빠르게 등록이 가능하며,\n유통기한 현황을 통해 버려지는 식자제를 줄이고\n금전적 손실을 줄일 수 있습니다.`,
  3: `놓치기 쉬운 사업장 업무를\n꼼꼼하게 관리할 수 있도록 도와줍니다.`,
  4: `사업주가 작성한 지시사항에 대해\n직원들과 소통이 가능하고,\n업무 실수 리스크가 감소합니다.`,
  5: `복잡하고 어려운 급여명세서를\n쉽고 빠르게 작성하고 터치 한 번으로\n간편하게교부할 수 있습니다.`,
};

function Intro(props) {
  const [tab, setTab] = useState(0);

  // tab 클릭 시 화면 전환
  $('.tabsLi').on('click', function () {
    $('.tabsLi').removeClass('active');
    $(this).addClass('active');

    $('.cnt')
      .css(
        {
          'margin-top': '150px',
          opacity: '0',
        },
        200,
      )
      .stop()
      .animate({
        'margin-top': '0px',
        opacity: '1',
      });
  });

  const clickTab = (tabIndex, content_name) => {
    setTab(tabIndex);
    dataLayer.push({
      event: 'show_content',
      content_name,
      url_path: window.location.pathname,
    });
  };

  return (
    <Div100vh>
      <div
        id="ContentWrap"
        className="col-xl-12 d-flex flex-row justify-content-center align-items-center overflow-hidden"
      >
        <div
          id="cntWrap"
          className="IntroWrap col-12 d-flex flex-row justify-content-center align-items-center"
        >
          <div className="wrap d-flex flex-row col-xl-10 col-lg-10 col-md-10 col-sm-10 justify-content-between flex-wrap">
            <div className="menuBar col-xl-5 col-lg-7 col-md-4 col-sm-12 d-flex flex-column justify-content-end">
              <h2 className="introTitle">샵솔 기능 소개</h2>
              <ul className="tabs col-xl-12 col-lg-8 col-md-12 col-sm-10 d-flex flex-row flex-wrap">
                <li
                  className={`tabsLi funcBtn1 show_content_btn${
                    tab === 0 ? ' active' : ''
                  }`}
                  onClick={() => clickTab(0, '출퇴근관리')}
                >
                  출퇴근관리
                </li>
                <li
                  className={`tabsLi funcBtn2 show_content_btn${
                    tab === 1 ? ' active' : ''
                  }`}
                  onClick={() => clickTab(1, '자동급여계산')}
                >
                  자동급여계산
                </li>
                <li
                  className={`pay tabsLi funcBtn3 show_content_btn${
                    tab === 5 ? ' active' : ''
                  }`}
                  onClick={() => clickTab(5, '급여명세서')}
                >
                  급여명세서
                </li>
                <li
                  className={`tabsLi funcBtn4 show_content_btn${
                    tab === 3 ? ' active' : ''
                  }`}
                  onClick={() => clickTab(3, '체크리스트')}
                >
                  체크리스트
                </li>
                <li
                  className={`tabsLi funcBtn5 show_content_btn${
                    tab === 4 ? ' active' : ''
                  }`}
                  onClick={() => clickTab(4, '업무일지')}
                >
                  업무일지
                </li>
                <li
                  className={`tabsLi funcBtn6 show_content_btn${
                    tab === 2 ? ' active' : ''
                  }`}
                  onClick={() => clickTab(2, '유통기한관리')}
                >
                  유통기한관리
                </li>
              </ul>
              <p className="introEx display-linebreak">{introEx[tab]}</p>
              <p className="introEx2 display-linebreak">{introEx2[tab]}</p>
            </div>
            <div
              id="contentArea"
              className="col-xl-7 col-lg-5 col-md-7 col-sm-10 d-flex flex-row justify-content-center align-items-center"
            >
              <div className="cnt d-flex flex-row justify-content-center align-items-center">
                {tabList[tab]}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Div100vh>
  );
}

export default Intro;
