import React, { useState, useEffect } from 'react';
import { Link, Route } from 'react-router-dom';
import './css/L01_Header.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import logoWhite from './img/logo-white2.png';
import logoColor from './img/logo-color2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faTimes,
  faCoins,
  faPercentage,
  faQuestion,
  faBlog,
} from '@fortawesome/free-solid-svg-icons';

function L01_Header(props) {
  closeSubmenuWhenBodyTouches();
  const [headerSet, setHeaderSet] = useState(false);
  useEffect(() => {
    if (props.cssStatus == headerSet) {
      $('#HeaderWrap').css({ color: '#333', backgroundColor: 'white' });
      $('.logo').attr('src', logoColor);
      $('.menuA').removeClass('menuStyle1');
      $('.menuA').addClass('menuStyle2');
      $('.icon').css('color', '#333');
    } else {
      $('#HeaderWrap').css({ color: 'white', backgroundColor: 'transparent' });
      $('.logo').attr('src', logoWhite);
      $('.menuA').removeClass('menuStyle2');
      $('.menuA').addClass('menuStyle1');
      $('.icon').css('color', 'white');
    }
  });

  function subMenuOpen(e) {
    e.preventDefault(e);
    $('#subMenu').show();
    $('#subMenu').css({ right: '-100%' });
    $('#subMenu').animate({ right: '0px' }, { duration: 700 });
  }

  function subMenuClose(e) {
    e.preventDefault(e);
    $('#subMenu').animate({ right: '-100%' }, { duration: 700 });
  }

  function closeSubmenuWhenBodyTouches() {
    $(document).on('click', function (e) {
      let subMenu = $('#subMenu');
      if (subMenu.has(e.target).length === 0) {
        subMenu.hide();
      }
    });
  }

  function reload() {
    window.location.replace('/');
  }

  function reloadPage() {
    window.location.reload();
  }

  return (
    <div
      id="HeaderWrap"
      className="col-12 d-flex flex-row justify-content-center headerBack"
    >
      <div className="d-flex flex-row justify-content-between align-items-center col-10">
        <div className="col-10 d-flex flex-row">
          <a id="logo" onClick={() => reload()}>
            <img
              className="logo"
              alt="샵솔 로고"
              src={logoWhite}
              width="80"
              height="36"
            />
          </a>
          <ul className="menuWrap d-flex flex-row align-items-center p-0 m-0">
            {/* <li className="menuLi menuShow">
                            <a className="menuA menuStyle1">
                                커스터마이징
                            </a>
                        </li> */}
            <li className="menuLi menuShow" onClick={reloadPage}>
              <Link className="menuA menuStyle1 menuIndex1" to="/price">
                요금제
              </Link>
            </li>
            {/* <li className="menuLi menuShow" onClick={ reloadPage }>
                            <Link className="menuA menuStyle1 menuIndex2" to="/boucher">
                                90%할인바우처
                            </Link>
                        </li> */}
            <li className="menuLi menuShow" onClick={reloadPage}>
              <Link className="menuA menuStyle1 menuIndex3" to="/FAQ">
                FAQ
              </Link>
            </li>
            <li className="menuLi menuShow">
              <a
                className="menuA menuStyle1 menuIndex4"
                href="https://blog.naver.com/wesop_co"
                target="_blank"
                onClick={() => {
                  dataLayer.push({
                    event: 'view_content',
                    content_name: 'blog_header',
                    next_url: 'https://blog.naver.com/wesop_co',
                    url_path: window.location.pathname,
                  });
                }}
              >
                블로그
              </a>
            </li>
          </ul>
        </div>
        <div className="col-2 menuWrap d-flex flex-row col-1 justify-content-end align-items-center">
          <li className="menuLi">
            <a
              className="menuA menuStyle1 login"
              target="_blank"
              href="https://ceo.shop-sol.com"
              onClick={() => {
                dataLayer.push({
                  event: 'view_content',
                  content_name: 'header_login_ceo',
                  next_url: 'https://ceo.shop-sol.com',
                  url_path: window.location.pathname,
                });
              }}
            >
              로그인
            </a>
          </li>
          <FontAwesomeIcon
            icon={faBars}
            className="icon"
            onClick={subMenuOpen}
          />
        </div>
        <div id="subMenu">
          <li className="subMenuTimes">
            <FontAwesomeIcon
              icon={faTimes}
              className="icon2"
              onClick={subMenuClose}
            />
          </li>
          <div className="d-flex flex-column">
            <div className="subLogin">
              <p>똑! 소리나는 사장님 반갑습니다.</p>
            </div>
            <div onClick={reloadPage}>
              <Link className="subText" to="/price">
                <FontAwesomeIcon
                  icon={faCoins}
                  className="subIcon"
                  onClick={subMenuClose}
                />
                <p className="subP">요금제</p>
              </Link>
            </div>
            {/* <div onClick={reloadPage}>
              <Link className="subText" to="/boucher">
                <FontAwesomeIcon
                  icon={faPercentage}
                  className="subIcon"
                  onClick={subMenuClose}
                />
                <p className="subP">90%할인바우처</p>
              </Link>
            </div> */}
            <div onClick={reloadPage}>
              <Link className="subText" to="/FAQ">
                <FontAwesomeIcon
                  icon={faQuestion}
                  className="subIcon"
                  onClick={subMenuClose}
                />
                <p className="subP">FAQ</p>
              </Link>
            </div>
            <div>
              <a
                className="subText"
                href="https://blog.naver.com/wesop_co"
                target="_blank"
                onClick={() => {
                  dataLayer.push({
                    event: 'view_content',
                    content_name: 'blog_menu',
                    next_url: 'https://blog.naver.com/wesop_co',
                    url_path: window.location.pathname,
                  });
                }}
              >
                <FontAwesomeIcon
                  icon={faBlog}
                  className="subIcon"
                  onClick={subMenuClose}
                />
                <p className="subP">블로그</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default L01_Header;
