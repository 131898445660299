import React from 'react';
import styled from 'styled-components';
import LogoImage from '../../images/new-logo.png';
import device from '../../Styles/Device';

const LogoArea = styled.div`
  text-align: center;
`;
const Logo = styled.img`
  width: 150px;
  @media ${device.phone} {
    width: 150px;
  }
`;

export default () => {
  return (
    <LogoArea>
      <a href="/">
        <Logo src={LogoImage} />
      </a>
    </LogoArea>
  );
};
