import React, { useEffect, useState } from 'react';
import { Link, Route } from 'react-router-dom';
import './css/L01_Header.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import logoWhite from './img/logo-white2.png';
import logoColor from './img/logo-color2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faTimes,
  faCoins,
  faPercentage,
  faQuestion,
  faBlog,
} from '@fortawesome/free-solid-svg-icons';

function L01_Header_sub() {
  closeSubmenuWhenBodyTouches();

  function subMenuOpen(e) {
    e.preventDefault(e);
    $('#subMenu').show();
    $('#subMenu').css({ right: '-100%' });
    $('#subMenu').animate({ right: '0px' }, { duration: 700 });
  }

  function subMenuClose(e) {
    e.preventDefault(e);
    $('#subMenu').animate({ right: '-100%' }, { duration: 700 });
  }

  function closeSubmenuWhenBodyTouches() {
    $(document).on('click', function (e) {
      let subMenu = $('#subMenu');
      if (subMenu.has(e.target).length === 0) {
        subMenu.hide();
      }
    });
  }

  function reload() {
    window.location.replace('/');
  }

  return (
    <div
      id="HeaderWrap"
      className="col-12 d-flex flex-row justify-content-center headerWrap2"
    >
      <div className="d-flex flex-row justify-content-between align-items-center col-10">
        <div className="col-10 d-flex flex-row">
          <a onClick={() => reload()}>
            <img
              className="logo"
              alt="샵솔 로고"
              src={logoColor}
              width="80"
              height="36"
            />
          </a>
          <ul className="menuWrap d-flex flex-row col-5 align-items-center p-0 m-0">
            {/* <li className="menuLi">
                            <Link className="menuA menuStyle2" to="/custom">
                                커스터마이징
                            </Link>
                        </li> */}
            <li className="menuLi">
              <Link className="menuA menuStyle2" to="/price">
                요금제
              </Link>
            </li>
            {/* <li className="menuLi">
                            <Link className="menuA menuStyle2" to="/boucher">
                                90%할인바우처
                            </Link>
                        </li> */}
            <li className="menuLi">
              <Link className="menuA menuStyle2" to="/FAQ">
                FAQ
              </Link>
            </li>
            <li className="menuLi">
              <a
                href="https://blog.naver.com/wesop_co"
                target="_blank"
                className="menuA menuStyle2"
              >
                블로그
              </a>
            </li>
          </ul>
        </div>
        <div className="col-2 menuWrap d-flex flex-row col-1 justify-content-end align-items-center">
          <li className="menuLi">
            <a
              className="menuA menuStyle2"
              target="_blank"
              href="https://ceo.shop-sol.com"
            >
              로그인
            </a>
          </li>
          <FontAwesomeIcon
            icon={faBars}
            className="icon icon_sub"
            onClick={subMenuOpen}
          />
        </div>
        <div id="subMenu">
          <li className="subMenuTimes">
            <FontAwesomeIcon
              icon={faTimes}
              className="icon2"
              onClick={subMenuClose}
            />
          </li>
          <div className="d-flex flex-column">
            <div className="subLogin">
              <p>똑! 소리나는 사장님 반갑습니다.</p>
            </div>
            <Link className="subText" to="/price">
              <FontAwesomeIcon
                icon={faCoins}
                className="subIcon"
                onClick={subMenuClose}
              />
              <p className="subP">요금제</p>
            </Link>
            {/* <Link className="subText" to="/boucher">
                            <FontAwesomeIcon 
                                icon={faPercentage} 
                                className="subIcon"
                                onClick={subMenuClose}
                            />
                            <p className="subP">90%할인바우처</p>
                        </Link> */}
            <Link className="subText" to="/FAQ">
              <FontAwesomeIcon
                icon={faQuestion}
                className="subIcon"
                onClick={subMenuClose}
              />
              <p className="subP">FAQ</p>
            </Link>
            <a
              className="subText"
              href="https://blog.naver.com/wesop_co"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faBlog}
                className="subIcon"
                onClick={subMenuClose}
              />
              <p className="subP">블로그</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default L01_Header_sub;
