import React, { useState, useEffect, useCallback } from 'react';
import { Link, Route } from 'react-router-dom';
import './css/L02_Visual.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import Apple from '../../../src/images/apple_copy.png';
import Google from '../../../src/images/google_copy.png';

const topText = ['프랜차이즈 필수 업무 파트너', 'all-in-one 사업장관리 솔루션'];
const titleText = ['샵의 솔루션, 샵솔!', '분산된 관리 한번에, 샵솔!'];

const exText = [
  '전국 프랜차이즈 가맹점 운영·관리부터',
  '출퇴근관리, 자동급여관리, 업무일지, 체크리스트',
];

const ex2Text = [
  '직원 근태관리까지 앱 하나로 효율성을 높이세요.',
  '그리고 급여명세서 교부까지 한 번에 관리하세요.',
];

function Visual(props) {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = () => {
      setIndex((prevIndex) => {
        if (prevIndex === topText.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      });
    };

    setInterval(timer, 3000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div id="ContentWrap col-12" style={{ height: '100vh' }}>
      <div className="vsaImgWrap">
        <div className="vsaImg"></div>
      </div>
      <div
        id="cntWrap"
        className="VisualWrap d-flex flex-column justify-content-center align-items-center"
      >
        <div
          id="visualTextWrap"
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <p className="visualText text1">{topText[index]}</p>
          <p className="visualText text2">{titleText[index]}</p>
          <p className="visualText text3">{exText[index]}</p>
          <p className="visualText text4">{ex2Text[index]}</p>
        </div>
        <div id="btnWrap" className="d-flex flex-column mt-4">
          <div>
            <a
              className="visualBtn introBtn1"
              target="_blank"
              href="https://bit.ly/3E2fXjO"
              onClick={() => {
                dataLayer.push({
                  event: 'view_content',
                  content_name: 'introduction_main_visual',
                  next_url: 'https://bit.ly/3E2fXjO',
                  url_path: window.location.pathname,
                });
              }}
            >
              도입문의 하기
            </a>
          </div>
          <div>
            <Link
              to="/join"
              target="_blank"
              className="visualBtn oneMonthBtn"
              onClick={() => {
                dataLayer.push({
                  event: 'view_content',
                  content_name: 'join_main_visual',
                  next_url: 'https://bit.ly/3E2fXjO',
                  url_path: window.location.pathname,
                });
              }}
            >
              웹 관리자 회원가입
            </Link>
          </div>
        </div>
        <div className="downloadWrap">
          <div className="downloadText">
            앱은 기한 상관없이 무료 사용이 가능합니다.
            <br />
            지금 바로 다운로드 하세요!
          </div>
          <div className="downloadBtnArea">
            <a
              id="AppStore"
              target="_blank"
              href="https://apple.co/3xXLa4R"
              alt="storeMoveBtnTop"
            >
              <img
                className="downloadBtn downLeft goAppStore"
                src={Apple}
                alt="storeMoveBtnTop"
              />
            </a>
            <a
              id="PlayStore"
              target="_blank"
              href="https://bit.ly/2XGMXrB"
              alt="storeMoveBtnTop"
            >
              <img
                className="downloadBtn goGooglePlayStore"
                src={Google}
                alt="storeMoveBtnTop"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Visual;
