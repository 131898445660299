import React from 'react';
import './css/L03_Intro.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import IntroImg from './img/intro3.png';
import IntroImg1 from './img/intro3_1.png';

function Intro_tab3() {
  let CountTxt = 0;

  $({ val: 7 }).animate(
    { val: CountTxt },
    {
      duration: 500,
      step: function () {
        let num = numberWithCommas(parseInt(this.val));
        $('.Count').text(`D-${num}`);
      },
      complete: function () {
        let num = numberWithCommas(parseInt(this.val));
        if (num == 0) {
          num = 'Day';
        }
        $('.Count').text(`D-${num}`);
      },
    },
  );

  function numberWithCommas(x) {
    return x.toString();
  }

  setTimeout(function () {
    $('.tab3_img1').css({ display: 'inline-block' });
  }, 600);

  return (
    <>
      <div className="tabWrap">
        <img src={IntroImg1} className="tab3_img1 animate__rotateIn" />
        <img src={IntroImg} className="tab_img tab3_img" />
      </div>
      <p className="Count"></p>
    </>
  );
}

export default Intro_tab3;
