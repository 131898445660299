import React from 'react';
import './css/L03_Intro.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import IntroImg from './img/intro1.png';
import IntroImg1 from './img/intro1_1.png';
import IntroImg2 from './img/intro1_2.png';

function Intro_tab1() {
  setTimeout(function () {
    $('.tab1_img2').css('display', 'inline-block');
  }, 500);

  setTimeout(function () {
    $('.tab1_img1').css('display', 'inline-block');
  }, 1000);

  return (
    <div className="tabWrap">
      <img
        src={IntroImg1}
        className="tab1_img1 animate__animated animate__fadeIn"
      />
      <img
        src={IntroImg2}
        className="tab1_img2 animate__animated animate__flipInX"
      />
      <img src={IntroImg} className="tab_img" />
    </div>
  );
}

export default Intro_tab1;
