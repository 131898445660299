// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR:wght@400;600&family=Noto+Sans+KR&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\";\nbody {\n  margin: 0;\n  padding: 0;\n}\n\n.section {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n}\n@media (max-width: 535px) {\n  .section {\n    height: auto;\n  }\n}\n\n.section > div {\n  width: 100%;\n  height: 100%;\n}\n\n/* 화면에서 스크롤바 안보이게 */\n#fullpage::-webkit-scrollbar {\n  display: none;\n}\n\n#fullpage {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}", "",{"version":3,"sources":["webpack://./src/components/Real/css/fullReal.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAGhB;EACE,SAAA;EACA,UAAA;AAAF;;AAGA;EACE,aAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AAAF;AAEE;EARF;IASI,YAAA;EACF;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA,mBAAA;AACA;EACE,aAAA;AACF;;AAEA;EACE,wBAAA;EACA,qBAAA;AACF","sourcesContent":["@import './mixin';\n@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR:wght@400;600&family=Noto+Sans+KR&display=swap');\n\nbody {\n  margin: 0;\n  padding: 0;\n}\n\n.section {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n\n  @media (max-width: 535px) {\n    height: auto;\n  }\n}\n\n.section>div {\n  width: 100%;\n  height: 100%;\n}\n\n/* 화면에서 스크롤바 안보이게 */\n#fullpage::-webkit-scrollbar {\n  display: none;\n}\n\n#fullpage {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
