import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import Portal from './Portal';
import device from './Device';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

function Modal ({ className, onClose, maskClosable, closable, visible, children }) {
    

    const onMaskClick = (e) => {
        if(e.target === e.currentTarget){
            onClose(e)
        }
    }
    const close = (e) => {
        if (onClose){
            onClose(e)
        }
    }

    useEffect(()=>{
        document.body.style.cssText = `position: fixed; top: -${window.scrollY}px; left: 0; right: 0;`
        return () => {
            const scrollY = document.body.style.top
            document.body.style.cssText = `position: "0"; top: "0";`
            window.scrollTo(0, parseInt(scrollY || '0') * -1)
        }
    }, [])

    return (
        <Portal elementId="modal-root">
            <ModalOverlay visible={visible}/>
            <ModalWrapper className={className} onClick={maskClosable ? onMaskClick : null} tabIndex="-1" visible={visible}>
                <ModalInner tabIndex="0" className="modal-inner">
                    {closable && <CloseButton classname="modal-close" onClick={close}>
                        <FontAwesomeIcon icon={faTimes} size="2x" color="#999"/>
                    </CloseButton>}
                    {children}
                </ModalInner>
            </ModalWrapper>
        </Portal>
    )
}

Modal.defaultProps = {
    closable: true,
    maskClosable: true,
    visible: false,
}

Modal.propTypes = {
    visible: PropTypes.bool,
}


const ModalWrapper = styled.div`
    box-sizing: border-box;
    display: ${(props) => (props.visible ? 'block' : 'none')};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
`;

const ModalOverlay = styled.div`
    box-sizing: border-box;
    display: ${(props) => (props.visible ? 'block' : 'none')};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 999;
`;

const ModalInner = styled.div`
    box-sizing: border-box;
    position: relative;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.5);
    background-color: #f5f6f7;
    border-radius: 10px;
    width: 500px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    padding: 40px 20px;
    @media ${device.phone} {
        width: 400px;
    }
`;

const CloseButton = styled.div`
    font-size: 10px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
`;

export default Modal