import React from 'react';
import './css/L08_Inquiry.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import InquiryBack from './img/inquiryBack.png';
import Div100vh from 'react-div-100vh';
import Apple from '../../../src/images/apple_copy.png';
import Google from '../../../src/images/google_copy.png';

function L10_InquiryAndFooter() {
  return (
    <Div100vh>
      <div
        id="InquiryContentWrap"
        className="d-flex flex-row justify-content-center align-items-center"
      >
        <div className="InquiryAndFooterWrap d-flex flex-column justify-content-center align-items-center col-12">
          <div className="InquiryWrap d-flex flex-column justify-content-center align-content-center text-center col-12">
            <p>체계적이고 효율적인 근무환경</p>
            <h1>지금 바로 샵솔을 도입해 보세요!</h1>
            <a
              className="btn footerIntroBtn"
              target="_blank"
              href="https://bit.ly/3E2fXjO"
              onClick={() => {
                dataLayer.push({
                  event: 'view_content',
                  content_name: 'introduction_footer',
                  next_url: 'https://bit.ly/3E2fXjO',
                  url_path: window.location.pathname,
                });
              }}
            >
              도입문의 하기
            </a>
            <div className="downloadWrap">
              <div className="downloadText">
                앱은 기한 상관없이 무료 사용이 가능합니다.
                <br />
                지금 바로 다운로드 하세요!
              </div>
              <div className="downloadBtnArea">
                <a
                  target="_blank"
                  href="https://apple.co/3xXLa4R"
                  id="AppStore"
                  alt="storeMoveBtnBottom"
                >
                  <img
                    className="downloadBtn downLeft footerAppStore"
                    src={Apple}
                    alt="storeMoveBtnBottom"
                  />
                </a>
                <a
                  target="_blank"
                  href="https://bit.ly/2XGMXrB"
                  id="PlayStore"
                  alt="storeMoveBtnBottom"
                >
                  <img
                    className="downloadBtn footerGoogle"
                    src={Google}
                    alt="storeMoveBtnBottom"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="FooterWrap d-flex flex-column justify-content-center align-content-center">
            <div className="iconWrap"></div>
            <p className="FooterText">
              상호명 : (주)위솝 | 대표 : 김규한 | 주소 : 서울 마포구 백범로31길
              21 413호
              <br />
              사업자등록번호 : 703-86-01169 | 통신판매업신고 :
              2020-서울마포-3722
              <br />
              이메일 문의 : help@wesop.co.kr | 전화 문의 : 070-8633-1119
            </p>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <a
                href="https://service.shop-sol.com/"
                target="_blank"
                onClick={() => {
                  dataLayer.push({
                    event: 'view_content',
                    content_name: 'footer_servicePolicy',
                    next_url: 'https://service.shop-sol.com/',
                    url_path: window.location.pathname,
                  });
                }}
              >
                서비스 이용약관
              </a>
              <a
                href="https://privacy.shop-sol.com/"
                target="_blank"
                onClick={() => {
                  dataLayer.push({
                    event: 'view_content',
                    content_name: 'footer_privacyPolicy',
                    next_url: 'https://privacy.shop-sol.com/',
                    url_path: window.location.pathname,
                  });
                }}
              >
                개인정보 처리방침
              </a>
              <a
                href="https://wesop.notion.site/e66532fa07ce4647bcf87afa31994cf2?pvs=4"
                target="_blank"
                onClick={() => {
                  dataLayer.push({
                    event: 'view_content',
                    content_name: 'footer_privacyPolicy',
                    next_url:
                      'https://wesop.notion.site/e66532fa07ce4647bcf87afa31994cf2?pvs=4',
                    url_path: window.location.pathname,
                  });
                }}
              >
                위치기반서비스 이용약관
              </a>
              <a
                href="https://wesop.notion.site/6380c3eb5c474a6abe32bddd7d1bd403?pvs=4"
                target="_blank"
                onClick={() => {
                  dataLayer.push({
                    event: 'view_content',
                    content_name: 'footer_privacyPolicy',
                    next_url:
                      'https://wesop.notion.site/6380c3eb5c474a6abe32bddd7d1bd403?pvs=4',
                    url_path: window.location.pathname,
                  });
                }}
              >
                개인위치정보 처리방침
              </a>
            </div>
          </div>
        </div>
      </div>
    </Div100vh>
  );
}

export default L10_InquiryAndFooter;
