import React from 'react';
import './css/L04_Section.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import SectionImg from './img/section1.png';
import SectionImg1 from './img/section1_1.png';
import Div100vh from 'react-div-100vh';

function L04_Section01() {
  return (
    <Div100vh>
      <div
        id="ContentWrap"
        className="d-flex flex-row justify-content-center align-items-center overflow-hidden"
      >
        <div
          id="cntWrap"
          className="d-flex align-items-center justify-content-center"
        >
          <div
            id="GPSImgWrap"
            className="d-flex flex-row justify-content-between align-items-center"
          >
            <div className="itemWrap">
              <img
                className="itemImg section1_img1 animate__animated animate__fadeInUp"
                alt="기능소개이미지"
                src={SectionImg1}
              />
            </div>
            <img
              className="section_img"
              alt="기능소개이미지"
              src={SectionImg}
            />
          </div>
          <div>
            <p className="sectionMiniTitle">허위출퇴근방지</p>
            <h2 className="sectionTitle">
              지정된 사업장 외 장소
              <br />
              출퇴근 체크 불가
            </h2>
            <p className="sectionText">
              샵솔은 GPS 출퇴근 체크하여
              <br />
              부정 출퇴근을 방지할 수 있습니다.
            </p>
          </div>
        </div>
      </div>
    </Div100vh>
  );
}

export default L04_Section01;
