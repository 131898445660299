import React from 'react';
import './css/L03_Intro.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import IntroImg from './img/intro6.png';
import IntroImg1 from './img/intro6_1.png';

function Intro_tab6() {
  setTimeout(function () {
    $('.tab6_img1').css('display', 'inline-block');
  }, 300);

  return (
    <div className="tabWrap">
      <img src={IntroImg} className="tab_img" />
    </div>
  );
}

export default Intro_tab6;
