import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyles from '../Styles/GlobalStyles';
import Theme from '../Styles/Theme';
import Main from './Real/fullReal';
import Join from './join/Main_join';
import Boucher from './Real/L12_sub_boucher';
import appCummunity from './appCummunity';
import Price from './Real/L11_sub_price';
import FAQ from './Real/L14_sub_FAQ';
import Custom from './Real/L13_sub_custom';
import './Real/css/style.scss';

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
`;

const App = () => {
  // console.log = () => {};

  return (
    <ThemeProvider theme={Theme}>
      <>
        <GlobalStyles />
        <Wrapper>
          <Switch>
            {/* 라우터 추가 시 ./Routes.js에 추가 후 빌드 필요 (구글 크롤링 시 사이트맵 제공) */}
            <Route path="/" component={Main} exact />
            <Route path="/join" component={Join} />
            <Route path="/price" component={Price} />
            <Route path="/boucher" component={Boucher} />
            <Route path="/appCummunity" component={appCummunity} />
            <Route path="/FAQ" component={FAQ} />
            <Route path="/custom" component={Custom} />
          </Switch>
        </Wrapper>
      </>
    </ThemeProvider>
  );
};

export default App;
