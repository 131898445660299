import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import PopImg from './img/popup.png';
import PopEvent from './img/popupEvent.jpg';
import RenualPopImg from './img/mainPreviewPopup2.png';
import PlaystorePopImg from './img/main_popup_23051801.png';
import prevBtn from './img/previewRenual/prevBtn.png';
import nextBtn from './img/previewRenual/nextBtn.png';
import previewRenualPop1 from './img/previewRenual/previewRenualPop1.png';
import previewRenualPop2 from './img/previewRenual/previewRenualPop2.png';
import previewRenualPop3 from './img/previewRenual/previewRenualPop3.png';
import previewRenualPop4 from './img/previewRenual/previewRenualPop4.png';
import previewRenualPop5 from './img/previewRenual/previewRenualPop5.png';
import previewRenualPop6 from './img/previewRenual/previewRenualPop6.png';
import previewRenualPop7 from './img/previewRenual/previewRenualPop7.png';
import previewRenualPop8 from './img/previewRenual/previewRenualPop8.png';
import './css/Popup.scss';

const Popup = (props) => {
  const { popup, setPopup } = props;
  const [firstPop, setFirstPop] = useState(true);
  const [secondPop, setSecondPop] = useState(true);
  // 기기 사이즈별 관리 - true: PC, false: tablet, mobile
  const [device, setDevice] = useState(true);
  const [deviceLink, setDeviceLink] = useState({
    os: '',
    link: '',
  });

  const [thirdPop, setThirdPop] = useState(true);
  const [showPrevPop, setPrevPop] = useState(false);
  const [prevPopIdx, setPrevPopIdx] = useState(0);
  const [prevTitle, setPrevTitle] = useState(
    '근태, 업무 상황을 한눈에 볼 수 있는 대시보드',
  );
  const [prevImg, setPrevImg] = useState(previewRenualPop1);

  const clickHideToday = () => {
    setPopup(popup - 1);
    setThirdPop(false);
    localStorage.setItem('hideDate', moment().format('YYYY-MM-DD'));
  };

  const previewRenualBtnClick = () => {
    setThirdPop(false);
    setPrevPop(true);
    dataLayer.push({
      event: 'view_content',
      content_name: 'firstPop',
      next_url: 'https://bit.ly/3BZVtWx',
      url_path: window.location.pathname,
    });
  };

  const prevIdx = useCallback(() => {
    if (prevPopIdx !== 0) setPrevPopIdx((prev) => prev - 1);
  }, [prevPopIdx]);
  const nextIdx = useCallback(() => {
    if (prevPopIdx !== 7) setPrevPopIdx((prev) => prev + 1);
  }, [prevPopIdx]);

  const handlePrevPop = () => {
    switch (prevPopIdx) {
      case 0:
        setPrevTitle('근태, 업무 상황을 한눈에 볼 수 있는 대시보드');
        setPrevImg(previewRenualPop1);
        break;
      case 1:
        setPrevTitle('한 주의 일정을 확인하는 일정관리');
        setPrevImg(previewRenualPop2);
        break;
      case 2:
        setPrevTitle('하루의 일정을 확인하는 일정관리');
        setPrevImg(previewRenualPop3);
        break;
      case 3:
        setPrevTitle('엑셀로 다운받을 수 있는 급여대장');
        setPrevImg(previewRenualPop4);
        break;
      case 4:
        setPrevTitle('템플릿으로 간편하게 등록하는 체크리스트');
        setPrevImg(previewRenualPop5);
        break;
      case 5:
        setPrevTitle('카카오톡으로 쉽고 빠르게 발송하는 급여명세서');
        setPrevImg(previewRenualPop6);
        break;
      case 6:
        setPrevTitle('다수의 직원을 엑셀로 일괄 등록');
        setPrevImg(previewRenualPop7);
        break;
      case 7:
        setPrevTitle(
          `고객님의 사랑으로 성장한 샵솔이\n새로워진 관리자페이지로 돌아옵니다.\n늘 발전하는 모습 보여드릴게요!`,
        );
        setPrevImg(previewRenualPop8);
        break;
    }
  };

  // 미리보기 팝업 표시 중 이미지 인덱스 변경 시
  useEffect(() => {
    handlePrevPop();
  }, [prevPopIdx]);

  useEffect(() => {
    let varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
    let mobile = /iphone|ipad|ipod|android/i.test(
      navigator.userAgent.toLowerCase(),
    );
    if (mobile) {
      //모바일 처리
      setDevice(false);
    } else {
      //비 모바일 처리
      setDevice(true);
    }

    if (varUA.indexOf('android') > -1) {
      //안드로이드
      setDeviceLink({
        ...deviceLink,
        os: 'Android',
        link: 'https://play.google.com/store/apps/details?id=com.wesop.appshopsol',
      });
    } else if (
      varUA.indexOf('iphone') > -1 ||
      varUA.indexOf('ipad') > -1 ||
      varUA.indexOf('ipod') > -1
    ) {
      //IOS
      setDeviceLink({
        ...deviceLink,
        os: 'ios',
        link: 'https://apps.apple.com/kr/app/id1408364175',
      });
    }
  }, []);

  const hideDateItem = localStorage.getItem('hideDate');
  const wrapBg = showPrevPop ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.4)';
  const hideTodayRenualPopup = hideDateItem === moment().format('YYYY-MM-DD');

  useEffect(() => {
    if (hideTodayRenualPopup) setPopup(0);
  }, [hideTodayRenualPopup]);

  return (
    <div className="popWrap" style={{ background: wrapBg }}>
      {/* {firstPop ? (
      <div className="popBox first">
        <img alt="팝업이미지" src={PopImg} />
        <div className="btnWrap col-12">
          <a
            href="https://bit.ly/3BZVtWx"
            target="_blank"
            rel="noreferrer"
            className="col-6 btn btnLeft"
            onClick={() => {
              dataLayer.push({
                  event: 'view_content',
                  content_name: 'firstPop',
                  next_url: 'https://bit.ly/3BZVtWx',
                  url_path: window.location.pathname
              })
            }}
          >
            상세정보
          </a>
          <div className="btnLine"></div>
          <button
            type="button"
            className="col-6 btn btnRight"
            onClick={() => {
              setPopup(popup - 1);
              setFirstPop(false);
            }}
          >
            닫기
          </button>
        </div>
      </div>
    ) : null} */}
      {/* {secondPop ? (
        // <div className="popBox second">
        <div className="popBox middle">
          <img alt="팝업이미지" src={PopEvent} />
          <div className="btnWrap col-12">
            {device ? (
              <>
                <a
                  href={'https://bit.ly/3Ozq2qB'}
                  target="_blank"
                  rel="noreferrer"
                  className="col-6 btn btnLeft"
                  onClick={() => {
                    dataLayer.push({
                        event: 'view_content',
                        content_name: 'secondPop',
                        next_url: 'https://bit.ly/3Ozq2qB',
                        url_path: window.location.pathname
                    })
                  }}
                >
                  이벤트 상세보기
                </a>
                <div className="btnLine"></div>
              </>
            ) : (
              <>
                <a
                  href={
                    device.os == 'android' ? deviceLink.link : deviceLink.link
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="col-6 btn btnLeft"
                >
                  앱 다운로드
                </a>
                <div className="btnLine"></div>
              </>
            )}
            <button
              type="button"
              className="col-6 btn btnRight"
              onClick={() => {
                setPopup(popup - 2);
                setSecondPop(false);
              }}
            >
              닫기
            </button>
          </div>
        </div>
      ) : null} */}
      {!hideTodayRenualPopup && thirdPop ? (
        <div className="popBox third" style={{ overflow: 'visible' }}>
          <button
            className="closeBtn"
            onClick={() => {
              setPopup(popup - 1);
              setThirdPop(false);
              setPrevImg(0);
            }}
          >
            <FontAwesomeIcon icon={faXmark} size={'2x'} color={'#003F9D'} />
          </button>
          {/* <div>
          <img alt="플레이스토어 팝업이미지" src={PlaystorePopImg} />
        </div> */}
          <button onClick={previewRenualBtnClick}>
            <img alt="팝업이미지" src={RenualPopImg} />
          </button>
          <div className="closeTodayBtn" onClick={clickHideToday}>
            <button>하루동안 보지 않기</button>
          </div>
        </div>
      ) : null}
      {showPrevPop ? (
        <div className="popBox previewPop">
          <button
            className="closeBtn"
            onClick={() => {
              setPopup(popup - 1);
              setThirdPop(false);
            }}
          >
            <FontAwesomeIcon icon={faXmark} size={'2x'} color={'#fff'} />
          </button>
          <div className="cntArea">
            <div className="ctrlArea left">
              {prevPopIdx > 0 && (
                <button onClick={prevIdx}>
                  <img alt="미리보기 이전 버튼" src={prevBtn} />
                </button>
              )}
            </div>
            <div className="viewArea">
              <div className="titleArea">
                {prevTitle.split(`\n`).map((text) => {
                  return (
                    <strong
                      style={{ fontSize: prevPopIdx === 7 ? '2.5rem' : '2rem' }}
                    >
                      {text}
                    </strong>
                  );
                })}
                {prevPopIdx === 7 && <span>-샵솔 일동-</span>}
              </div>
              <div className="imgArea">
                <img
                  alt="미리보기 이미지"
                  src={prevImg}
                  style={{ width: prevPopIdx === 7 ? '70%' : '100%' }}
                />
              </div>
            </div>
            <div className="ctrlArea">
              {prevPopIdx < 7 && (
                <button onClick={nextIdx}>
                  <img alt="미리보기 다음 버튼" src={nextBtn} />
                </button>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Popup;
