import React, { useState, useEffect, useRef } from 'react';
import './css/L14_sub_FAQ.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import Header from './L01_Header_sub';
import Meta from '../MetaTag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

function L15_FAQ() {
  const [headerCSS, setHeaderCSS] = useState(true);

  useEffect(() => {
    setHeaderCSS(false);
  });

  $(document).ready(function () {
    $('.tab_title li').click(function () {
      let idx = $(this).index();
      $('.tab_title li').removeClass('on');
      $('.tab_title li').eq(idx).addClass('on');
      $('.tab_cont > div').hide();
      $('.tab_cont > div').eq(idx).show();
    });

    $('.Q')
      .off()
      .click(function () {
        $(this).next().slideToggle();
        let arrowIcon = $(this).children('.icon');
        const content = $(this).children('p').text();
        if (arrowIcon.hasClass('close')) {
          arrowIcon.removeClass('close');
          arrowIcon.addClass('open');

          dataLayer.push({
            event: 'show_faq',
            faq_content: content,
          });
        } else {
          arrowIcon.removeClass('open');
          arrowIcon.addClass('close');
        }
      });

    $('li').on('click', function () {
      window.scrollTo(0, 0);
    });
  });

  return (
    <>
    <Meta />
    <div className="FAQWrap">
      <Header cssStatus={headerCSS} />
      <div className="container col-12">
        <h2 className="FAQtitle">
          <b>FAQ </b>자주 묻는 질문
        </h2>
        <div className="tab_cont col-xl-10 col-lg-11 mt-4">
          <div className="on box">
            <div className="list">
              <div className="Q">
                <p>
                  Q. 앱을 깔고 회원가입을 했는데 그 다음은 어떤 걸 진행해야
                  하나요?
                </p>
                <FontAwesomeIcon icon={faChevronDown} className="icon close" />
              </div>
              <p className="A">
                A. 회원가입을 마치셨다면 직원 초대를 하셔야 합니다.
                <br />
                <br />
                샵솔 메인화면에서 [직원 초대] 탭으로 들어가신 후 [연락처로 추가]
                또는 [직접 입력]을 통해 초대할 직원 목록을 생성해 주세요.
                <br />
                <br />
                초대할 직원의 휴대폰 번호를 목록을 생성하셨다면, [직원 초대
                완료] 버튼을 눌러주세요. 직원 초대장은 해당 직원의 카카오톡으로
                발송됩니다.
                <br />
                <br />
                초대장을 받은 직원은 [앱 설치] 버튼을 눌러 앱을 설치 후
                회원가입을 진행합니다.
                <br />
                <br />
                직원의 [회원가입]이 완료되면, 사업주님은 샵솔 메인화면 [직원
                합류 승인] 탭에서 해당직원을 [승인]하셔야 비로소 모든 직원
                초대가 완료됩니다.
              </p>
            </div>
            <div className="list">
              <div className="Q">
                <p>Q. 퇴사한 직원은 목록에서 어떻게 삭제하나요?</p>
                <FontAwesomeIcon icon={faChevronDown} className="icon close" />
              </div>
              <p className="A">
                A. 퇴사한 직원과 근무 중인 직원을 분류할 수 있습니다.
                <br />
                <br />
                샵솔 메인화면에서 [직원 목록] 탭으로 들어가서, 퇴사한 해당
                직원을 선택한 후 [정보 수정]을 눌러주세요.
                <br />
                <br />
                [출퇴근 정보] 탭에서 [퇴사일 미정] 체크를 풀고, [퇴사일을
                설정]하면 해당 날짜 다음날 퇴사한 직원으로 분류됩니다.
                <br />
                <br />
                분류 확인은 [직원 목록] 탭에서 확인할 수 있습니다.
              </p>
            </div>
            <div className="list">
              <div className="Q">
                <p>
                  Q. 퇴사한 직원이 재입사했는데 직원목록에 어떻게 추가하나요?
                </p>
                <FontAwesomeIcon icon={faChevronDown} className="icon close" />
              </div>
              <p className="A">
                A. 샵솔 메인화면에서 [직원 목록] 탭으로 들어가신 후 [근무 종료]
                목록에서 [재입사한 해당 직원]을 선택해 주세요.
                <br />
                <br />
                [정보 수정]을 누른 후 [출퇴근 정보] 탭에서 [퇴사일 미정] 체크를
                하고 [수정 완료] 버튼을 누릅니다.
                <br />
                <br />
                다시 [직원 목록] 탭으로 돌아가면 해당직원이 [전체 직원]으로
                분류된 것을 확인할 수 있습니다.
              </p>
            </div>
            <div className="list">
              <div className="Q">
                <p>Q. GPS 기반 출퇴근 체크는 어떻게 사용하는 건가요?</p>
                <FontAwesomeIcon icon={faChevronDown} className="icon close" />
              </div>
              <p className="A">
                A. 직원은 근무 중인 해당 사업장에 도착 후 샵솔 앱을 통하여 GPS
                출퇴근 체크를 할 수 있습니다.
                <br />
                <br />
                직원은 샵솔 메인화면에서 [GPS출퇴근하기] 버튼을 누른 후,
                위치정보가 불러와지면 탭 하여 [출퇴근하기]를 누릅니다.
                <br />
                <br />
                [출근] 버튼과 [퇴근] 버튼 중 직원의 상황에 맞는 것을 터치하여
                출퇴근 시간을 기록합니다.
              </p>
            </div>
            <div className="list">
              <div className="Q">
                <p>Q. 직원이 출퇴근 버튼을 잘못 눌렀을 때 어떻게 하나요?</p>
                <FontAwesomeIcon icon={faChevronDown} className="icon close" />
              </div>
              <p className="A">
                A. 직원이 출근 버튼을 착각하여 퇴근 버튼을 눌렀다면
                070-8633-1119으로 전화주세요.
                <br />
                <br />
                샵솔에서 실수한 데이터를 처리해 드립니다. <br />
                <br />
                만약 직원이 출퇴근 체크를 잊고 본래 출근시간 또는 퇴근시간보다
                늦게 체크했다면,
                <br />
                <br />
                사업주님께서 샵솔 메인화면에서 [캘린더] 탭으로 들어가신 후 해당
                직원의 [출퇴근시간 수정하기]에서 시간을 변경할 수 있습니다.
              </p>
            </div>
            <div className="list">
              <div className="Q">
                <p>
                  Q. 해당직원의 특정 일만 출퇴근 시간을 변경하려면 어떻게 하죠?
                </p>
                <FontAwesomeIcon icon={faChevronDown} className="icon close" />
              </div>
              <p className="A">
                A. 샵솔 메인화면에서 [직원 목록] 탭으로 들어갑니다.
                <br />
                <br />
                - 이미 근무 일정이 있는 직원의 경우
                <br />
                [근무 일정] 탭에서 [수정] 버튼을 누릅니다.
                <br />
                설정된 요일 중 변경되어야 할 요일이 이미 등록이 되어있는
                경우라면 해당 요일을 삭제 후 다시 선택해 주세요.
                <br />
                일정이 없던 요일이라면 해당 요일을 선택하여 활성화합니다.
                <br />
                변경되는 [출퇴근 시간]을 입력하고 [추가하기] 버튼을 누릅니다.
                <br />
                변경된 일정이 잘 추가되었는지 확인 후 [일정 수정 완료] 버튼을
                누릅니다.
                <br />
                <br />
                - 근무 일정이 없는 직원의 경우
                <br />
                [근무 일정] 탭에서 [일정 추가] 버튼을 누릅니다.
                <br />
                [출퇴근 시간]을 입력 후 요일을 선택하여 [추가하기] 버튼을
                누릅니다.
                <br />
                같은 액션을 반복하여 설정하신 후 [일정 추가 완료] 버튼을
                누릅니다.
              </p>
            </div>
            <div className="list">
              <div className="Q">
                <p>Q. 하나의 계정에 여러 개의 점포 등록이 가능한가요?</p>
                <FontAwesomeIcon icon={faChevronDown} className="icon close" />
              </div>
              <p className="A">
                A. 샵솔은 여러 개의 사업장 등록이 가능합니다.
                <br />
                <br />
                샵솔 메인화면 상단에 [사업장 전환]으로 들어갑니다.
                <br />
                [사업장 등록하기] 버튼을 눌러 사업장 정보, 출퇴근 정보 설정,
                급여정보 설정을 입력 후 [등록하기]를 눌러주세요.
              </p>
            </div>
            <div className="list">
              <div className="Q">
                <p>
                  Q. 자율출퇴근으로 설정했는데 주휴수당 계산이 안 될 때 어떻게
                  하나요?
                </p>
                <FontAwesomeIcon icon={faChevronDown} className="icon close" />
              </div>
              <p className="A">
                A. 자율 출퇴근의 경우 급여계산 목적보다는 직원의 출퇴근 확인
                용도에 적합한 서비스입니다.
                <br />
                <br />
                자율 출퇴근은 일정이 없기 때문에 결근 여부를 판단할 수 없어
                주휴수당도 계산되지 않습니다.
                <br />
                수당 계산을 원하신다면 일정 출퇴근으로 설정을 변경하시기
                바랍니다.
              </p>
            </div>
            <div className="list">
              <div className="Q">
                <p>Q. 샵솔 PC관리자를 무료로 체험해 볼 수 있나요?</p>
                <FontAwesomeIcon icon={faChevronDown} className="icon close" />
              </div>
              <p className="A">
                A. 샵솔 PC관리자는 현재 1개월 무료체험이 가능합니다.
                <br />
                <br />
                회원가입이 되어 있으시다면 로그인 후 1개월 무료체험을 신청해
                보세요.
              </p>
            </div>
            <div className="list">
              <div className="Q">
                <p>Q. 매니저에게 관리자의 권한을 부여할 수 있나요?</p>
                <FontAwesomeIcon icon={faChevronDown} className="icon close" />
              </div>
              <p className="A">
                A. 매니저에게 관리자 권한을 부여하면 직원급여/일정 확인 및 수정,
                직원 캘린더 수정, 타 직원 출퇴근 알람 받기가 가능합니다.
                <br />
                <br />
                샵솔 메인화면에서 [직원 목록] 탭으로 들어갑니다.
                <br />
                해당 매니저를 선택한 후 [정보 수정]을 누릅니다.
                <br />
                하단 (선택) 직책/권한, 급여보기 탭을 펼칩니다.
                <br />
                부여할 권한을 선택 후 [수정 완료] 버튼을 누릅니다.
              </p>
            </div>
          </div>
          <div className="box">
            <div className="list">
              <div className="Q">
                <p>Q. PC에서도 사용이 가능한가요?</p>
                <FontAwesomeIcon icon={faChevronDown} className="icon close" />
              </div>
              <p className="A">
                A. 샵솔은 PC에서도 사용이 가능합니다.
                <br />
                PC사용은 유료 서비스이며 1개월 무료 체험이 가능합니다.
              </p>
            </div>
            <div className="list">
              <div className="Q">
                <p>Q. 근무 기록 엑셀을 다운로드 할 수 있나요?</p>
                <FontAwesomeIcon icon={faChevronDown} className="icon close" />
              </div>
              <p className="A">
                A. 엑셀 다운로드는 PC에서 가능하며 유료 서비스 입니다.
                <br />
                1개월 무료체험이 가능하니 회원이 아니시라면 회원가입 후 사용
                가능하며,
                <br />
                이미 회원이시라면 로그인 후 1개월 무료체험을 신청해 보시기
                바랍니다.
              </p>
            </div>
            <div className="list">
              <div className="Q">
                <p>Q. 회원탈퇴는 어떻게 하나요?</p>
                <FontAwesomeIcon icon={faChevronDown} className="icon close" />
              </div>
              <p className="A">
                A. 회원탈퇴 방법은 샵솔 메인 화면 상단 [마이페이지]에 들어가신
                후 [계정관리]에서 회원탈퇴를 하실 수 있습니다.
              </p>
            </div>
            <div className="list">
              <div className="Q">
                <p>
                  Q. 직원이 출퇴근 체크를 해도 알림이 오지 않는 경우 어떻게
                  하나요?
                </p>
                <FontAwesomeIcon icon={faChevronDown} className="icon close" />
              </div>
              <p className="A">
                A. 알림이 오지 않는다면 알림 기능이 활성화되어 있는지 확인을
                해보셔야 합니다.
                <br />
                <br />
                샵솔 메인페이지 상단 [마이페이지]에 들어가신 후 [알림 설정]에서
                확인하실 수 있습니다.
                <br />
                만약 모든 푸시가 활성화되어 있는데도 알림이 오지 않는다면 개인
                휴대폰에 있는 [설정] 앱에서 알림 기능이 활성화되어있는지 확인해
                보시기 바랍니다.
              </p>
            </div>
          </div>
        </div>
        <ul className="tab_title mt-4">
          <li className="on">1</li>
          <li className="page2">2</li>
        </ul>
      </div>
    </div>
    </>
  );
}

export default L15_FAQ;
