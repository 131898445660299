import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
import './css/fullReal.scss';
import 'fullpage.js/vendors/scrolloverflow';
import 'fullpage.js';
import 'fullpage.js/dist/jquery.fullpage.min.css';
import { isMobile } from 'react-device-detect';

import Meta from '../MetaTag';
import Header from './L01_Header';
import Visual from './L02_Visual';
import Intro from './L03_Intro';
import GPS from './L04_Section01';
import AlertService from './L04_Section02';
import TodayTodo from './L04_Section03';
import Payment from './L04_Section04';
import WorkList from './L04_Section05';
import Kakao from './L04_Section06';
// import UpdateFunction from './L16_UpdateFunction';
import WebManager from './L05_WebManager';
import Brand from './L06_Brand';
import Review from './L07_Review';
import Inquiry from './L08_Inquiry';
import GoTop from './L15_goTop';
import Popup from './Popup';

export default function FullPageLanding() {
  const location = useLocation();
  const [headerCSS, setHeaderCSS] = useState(false);
  const [topBtn, setTopBtn] = useState();
  const [popup, setPopup] = useState(0);
  const [resize, setResize] = useState();
  const [mobileStatus, setMobileStatus] = useState(false);

  const handleResize = () => {
    setResize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // console.log('resize', resize < 535);
    if (resize < 535) {
      setMobileStatus(true);
    } else {
      setMobileStatus(false);
    }
  }, [resize]);

  useEffect(() => {
    if (!isMobile) {
      setHeaderCSS(true);
      $(() => {
        $('#fullpage').fullpage({
          scrollOverflow: true,
          navigation: true,
          scrollHorizontally: true,
          showActiveTooltip: true,
          scrollingSpeed: 800,
          fitToSection: true,
          fitToSectionDelay: 1200,
          // 화면별 전환
          afterLoad: function (anchorLink, index) {
            window.IScroll = IScroll;
            let content = '';
            if (index === 1) {
              content = '환영합니다';
              setTopBtn(false);
              setHeaderCSS(true);
            } else if (index === 2) {
              content = '샵솔기능소개';
              setTopBtn(true);
              setHeaderCSS(true);
            } else if (index === 3) {
              content = 'GPS출퇴근';
              setTopBtn(true);
              setHeaderCSS(false);
            } else if (index === 4) {
              content = '출퇴근푸시알림';
              setTopBtn(true);
              setHeaderCSS(false);
            } else if (index === 5) {
              content = '체크리스트';
              setTopBtn(true);
              setHeaderCSS(false);
            } else if (index === 6) {
              content = '급여관리';
              setTopBtn(true);
              setHeaderCSS(false);
            } else if (index === 7) {
              content = '급여명세서발송';
              setTopBtn(true);
              setHeaderCSS(false);
            } else if (index === 8) {
              content = '체크리스트';
              setTopBtn(true);
              setHeaderCSS(false);
            } else if (index === 9) {
              content = 'PC관리자페이지';
              setTopBtn(true);
              setHeaderCSS(false);
            } else if (index === 10) {
              content = '업데이트 기능';
              setTopBtn(true);
              setHeaderCSS(true);
            } else if (index === 11) {
              content = '샵솔이용브랜드';
              setTopBtn(true);
              setHeaderCSS(false);
            } else if (index === 12) {
              content = '고객후기';
              setTopBtn(true);
              setHeaderCSS(true);
            } else if (index === 13) {
              content = '도입문의하기';
              setTopBtn(true);
              setHeaderCSS(false);
            }

            window.history.pushState({}, '', `#${index}_${content}`);
          },
        });
      });
    }
  }, []);

  const handleTop = () => {
    $.fn.fullpage.moveTo(1, 1);
    $.fn.fullpage.setScrollingSpeed(700);
  };

  useEffect(() => {
    console.log('팝업 카운트', popup);
  }, [popup]);

  return (
    <>
      <Meta />
      <div className="postion-relative">
        <Header cssStatus={headerCSS} />
        {topBtn === true ? <GoTop onClick={() => handleTop()} /> : null}
        {popup !== 0 ? <Popup popup={popup} setPopup={setPopup} /> : null}
        {/* {popup1 ? <Popup2 onClose={setPopup1} /> : null} */}
        <div id="fullpage">
          <div className="section">
            <Visual />
          </div>
          <div className="section">
            <Intro />
          </div>
          <div className="section">
            <GPS />
          </div>
          <div className="section">
            <AlertService />
          </div>
          <div className="section">
            <TodayTodo />
          </div>
          <div className="section">
            <Payment />
          </div>
          <div className="section">
            <Kakao />
          </div>
          <div className="section">
            <WorkList />
          </div>
          <div className="section">
            <WebManager />
          </div>
          {/* <div className="section">
            <UpdateFunction />
          </div> */}
          <div className="section">
            <Brand />
          </div>
          {mobileStatus && (
            <div id="review" className="section">
              <Review />
            </div>
          )}
          <div className="section">
            <Inquiry />
          </div>
        </div>
      </div>
    </>
  );
}
