import React from 'react';
import './css/L04_Section.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import SectionImg from './img/section2.png';
import SectionImg1 from './img/section2_1.png';
import Div100vh from 'react-div-100vh';

function L04_Section02() {
  return (
    <Div100vh>
      <div
        id="ContentWrap"
        className="col-12 d-flex flex-row justify-content-center align-items-center overflow-hidden"
      >
        <div
          id="cntWrap2"
          className="d-flex align-items-center justify-content-center"
        >
          <div className="d-flex flex-column alertTextWrap">
            <p className="sectionMiniTitle">알림서비스</p>
            <h2 className="sectionTitle">
              언제 어디서든
              <br />
              실시간 알림
            </h2>
            <p className="sectionText">
              시간과 장소에 구애 받지 않고
              <br />
              사업장 현황을 확인할 수 있습니다.
            </p>
          </div>
          <div
            id="alertImgWrap"
            className="d-flex flex-row justify-content-center align-items-center"
          >
            <div className="itemWrap">
              <img
                className="itemImg section2_img1 animate__animated animate__pulse"
                alt="기능소개이미지"
                src={SectionImg1}
              />
            </div>
            <img
              className="section_img2"
              alt="기능소개이미지"
              src={SectionImg}
            />
          </div>
        </div>
      </div>
    </Div100vh>
  );
}

export default L04_Section02;
