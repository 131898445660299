import React, { useState, useEffect, useRef } from 'react';
import './css/L12_sub_boucher.scss';
import './css/style.scss';
import 'bootstrap/dist/css/bootstrap.css';
import Meta from '../MetaTag';
import $ from './jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Header from './L01_Header_sub';
import BoucherImg from './img/boucher.png';
import Inquiry from './L08_Inquiry_sub';

export default () => {
  const [headerCSS, setHeaderCSS] = useState(true);

  useEffect(() => {
    setHeaderCSS(false);
  });
  return (
    <>
    <Meta />
    <div className="boucherWrap">
      <Header cssStatus={headerCSS} />
      <div className="boucherBox">
        <div className="boucherInner">
          <h2 className="boucherTitle">
            정부에서
            <br />
            중소기업대상
            <br />
            <b>90% </b>이용료 지원!
          </h2>
          <p className="boucherText">
            샵솔은
            <br />
            중소벤처기업부에서 주관하는
            <br />
            <b>'K-비대면 서비스 바우처사업'</b>의<br />
            공급업체로 최종 선정되었습니다.
            <br />
            <br />
            <b>90%할인된 금액</b>으로
            <br />
            집에서 사업장을 관리해 보세요!
          </p>
        </div>
        <a
          className="boucherBtn"
          target="_blank"
          href="https://wesopco.typeform.com/to/z0eK0UZS"
        >
          신청하기
        </a>
        <p className="boucherAddText">
          * 기존고객과 신규고객 모두 신청 가능합니다.
        </p>
      </div>
      <div>
        <img className="boucher_img" alt="바우처이지미" src={BoucherImg} />
      </div>
    </div>
    </>
  );
}
