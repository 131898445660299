import React from 'react';
import './css/L03_Intro.scss';
import 'bootstrap/dist/css/bootstrap.css';
import $ from './jquery';
import IntroImg from './img/intro4.png';
import IntroImg1 from './img/intro4_1.png';
import IntroImg2 from './img/intro4_2.png';

function Intro_tab4() {
  return (
    <div className="tabWrap">
      <img
        src={IntroImg1}
        className="tab4_img1 animate__animated animate__bounceInRight"
      />
      <img src={IntroImg2} className="tab4_img2" />
      <img src={IntroImg} className="tab_img tab4_img" />
    </div>
  );
}

export default Intro_tab4;
